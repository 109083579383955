<div class="n-section-breadcrumb">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-12">
                <span *ngFor="let item of model; index as i;">
                    <span *ngIf="i > 0"> - </span>
                    <a [routerLink]="item.url">{{item.linkText}}</a> 
                </span>
            </div>
        </div>
    </div>
</div>