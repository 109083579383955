import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalHeaderService } from '../global-header.service';
import { ContextService } from '../context.service';
import { HighlightOverview } from '../highlight-overview';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-highlight-overview',
  templateUrl: './highlight-overview.component.html',
  styleUrls: ['./highlight-overview.component.less']
})
export class HighlightOverviewComponent implements OnInit, OnDestroy {
  
  private subscriptions = new Subscription();

  public model : HighlightOverview

  constructor(
    private route: ActivatedRoute,
    private contextService: ContextService,
    private headerService: GlobalHeaderService ) {
  }

  ngOnInit() {
    this.model = new HighlightOverview();

    var subscription = this.route.paramMap.subscribe(params => {
      var itemsSubscription = this.contextService.getHighlights().subscribe(data => {
        this.model.items = data;
        this.headerService.setHead(this.model);
      });
      
      this.subscriptions.add(itemsSubscription);
    });
    
    this.subscriptions.add(subscription);
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
