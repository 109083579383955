import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Page } from './page';

@Injectable({
  providedIn: 'root'
})
export class GlobalHeaderService {

  constructor(private titleService: Title, private metaService: Meta) { 
  }

  public setTitle(title : string) : void 
  {
    title = title + ' - Skye Scenic Tours';

    this.titleService.setTitle(title);
  }

  public setHead(page : Page) : void 
  {
    this.setTitle(page.title);

    this.metaService.updateTag({ 
      name: 'description',
      content: page.description 
    });

    this.metaService.updateTag({ 
      name: 'keywords',
      content: page.keywords 
    });
  }

  public setNoIndex() : void 
  {
    this.metaService.addTag({ name: "robots", content: "noindex" });
  }
}
