export class Page 
{
    public id: number;
  
    public parentId: number;
  
    public name: string;
  
    public url : string;
  
    public createDate: Date;
  
    public title: string;

    public subTitle: string;

    public linkText: string;
  
    public description: string;

    public keywords: string;

    public summary: string[];

    public thumbnailUrl: string;

    public content: any[];

    public Page()
    {
        this.id = 1;
        this.parentId = -1;
        this.createDate = new Date(2020, 7, 17);
        this.summary = [];
        this.content = [];
    }
}