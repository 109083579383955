<app-global-cookie></app-global-cookie>
<app-global-menu></app-global-menu>
<app-global-breadcrumb></app-global-breadcrumb>

<div class="n-section-tour-header n-section-content-one">
  <app-why-home-section></app-why-home-section>
</div>

<div class="n-section-light n-section-content-one">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <div class="n-list-box-header">
          <h1>{{model!.title}}</h1>
          <p>{{model!.description}}</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">

        <div class="n-list-box-flush">
          <div class="n-list-box-flush-content">
            <p *ngFor="let text of model.content">
              {{text}}
            </p>
          </div>      
        </div>

      </div>
      <div class="col-md-4">

        <div class="n-list-box">
          <div class="n-list-box-content">
            <p>
              <a *ngIf="model.itemId > 0" [href]="'https://skyescenictours.checkfront.co.uk/reserve/?item_id=' + model.itemId" target="_blank" rel="noreferrer" class="btn btn-primary tourBookButton">Book (opens Checkfront)</a>
            </p>
            <p *ngFor="let text of model.pricing">
              <img src="/assets/style/icons8-black/icons8-checked-50.png" alt="Price check" class="tourBookPoint" />Only {{text}}
            </p>
            <p *ngFor="let text of model.points">
              <img src="/assets/style/icons8-black/icons8-checked-50.png" alt="Check" class="tourBookPoint" />{{text}}
            </p>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="n-section-lighter n-section-content-one">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <div class="n-list-box-header">
          <h2>Highlights Visited</h2>
          <p>The discoveries waiting to be made on {{model.title}}.</p>
        </div>
      </div>
    </div>
    <div class="row n-list-narrow-gutter"> 
      <div *ngFor="let item of model.highlights" class="col-6 col-sm-4 col-md-3">
        <app-highlight-tile  [model]="item"></app-highlight-tile>
      </div>
    </div>
  </div>
</div>

<div class="n-section-light n-section-content-one">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <div class="n-list-box-header">
          <h2>Itinerary</h2>
          <p>{{model.title}} itinerary.</p>
        </div>
      </div>
    </div>
    <div class="row n-list-narrow-gutter"> 
      <div *ngFor="let text of model.timeline" class="col-md-6">

        <div class="n-list-box" >
          <div class="n-list-box-content">
              <p>{{text}}</p>
          </div>
        </div>

      </div>
    </div>
    <div class="row"> 
      <div class="col-12">

        <div class="n-list-box">
          <div class="n-list-box-content">
            <h3>Good to know</h3>
            <p *ngFor="let text of model.conditions">
              {{text}}
            </p>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="n-section-lighter n-section-content-one">
  <div class="container">

    <div class="row">
      <div class="col-md-8">
        <div class="n-list-box-header">
          <h2>Pricing &amp; Availability</h2>
        </div>
      </div>
    </div>
    <div class="row"> 
      <div *ngFor="let text of model.pricing" class="col-12">

        <div class="n-list-box">
          <div class="n-list-box-content">
            <p>
              <strong>{{text}}</strong>
            </p>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="n-section-lighter">
  <div class="n-section-content-two">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="n-list-box tourWhyBox">
            <div class="n-list-content">
              <div class="container">
                <div class="row">
                  <div class="col-md-3">
                    <div class="n-list-box-flush-icon">
                      <img class="n-list-box-flush-icon-icon" src="/assets/style/icons8-white/icons8-minibus-50.png" alt="Travel in comfort" />
                      <div class="n-list-box-flush-icon-content">
                        <h5>Travel in comfort</h5>
                        <p>Our minibus has capacity for 8 occupants with plenty of space to travel in perfect comfort.</p>
                        <p>Our vehicles have large windows allow all occupants a perfect view of the stunning scenery of the Isle of Skye.</p>        
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="n-list-box-flush-icon">
                      <img class="n-list-box-flush-icon-icon" src="/assets/style/icons8-white/icons8-slr-camera-50.png" alt="Photo Opportunities" />
                      <div class="n-list-box-flush-icon-content">
                        <h5>Photo Opportunities</h5>
                        <p>Our tour routes take in all the stunning sights of the Isle of Skye and we will allow plenty of time to stop on the route to allow you to take photos of your time on the Isle of Skye.</p>                  
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="n-list-box-flush-icon">
                      <img class="n-list-box-flush-icon-icon" src="/assets/style/icons8-white/icons8-eye-50.png" alt="Best way to see the Isle of Skye" />
                      <div class="n-list-box-flush-icon-content">
                        <h5>Best way to see the Isle of Skye</h5>
                        <p>Skye Scenic Tours will allow you ample time to take in the sights of the stunning Isle of Skye.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="n-list-box-flush-icon">
                      <img class="n-list-box-flush-icon-icon" src="/assets/style/icons8-white/icons8-map-50.png" alt="Local Knowledge" />
                      <div class="n-list-box-flush-icon-content">
                        <h5>Local Knowledge</h5>
                        <p>Our Driver Guides have all lived in the local area for many years and know the best and most scenic places to visit during your tour.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>   
      </div>
    </div>
  </div>
</div>

<div class="n-section-contact n-section-light">
  <div class="n-section-content-one">
    <div class="container">
      <app-contact-form></app-contact-form>
    </div>
  </div>
</div>

<app-global-footer></app-global-footer>