import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, provideHttpClient } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntypedFormBuilder }  from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// import { BrowserModule } from '@angular/platform-browser';
// import { NgModule } from '@angular/core';
// import { RouterModule, Routes } from '@angular/router';
// import { HttpClientModule } from '@angular/common/http';
// import { AppRoutingModule } from './app-routing.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TourComponent } from './tour/tour.component';
import { HighlightComponent } from './highlight/highlight.component';
import { HomeComponent } from './home/home.component';
import { AccommodationComponent } from './accommodation/accommodation.component';
import { ImageComponent } from './image/image.component';
import { TourOverviewComponent } from './tour-overview/tour-overview.component';
import { HighlightOverviewComponent } from './highlight-overview/highlight-overview.component';
import { AccommodationOverviewComponent } from './accommodation-overview/accommodation-overview.component';
import { ImageOverviewComponent } from './image-overview/image-overview.component';
//import { ContactComponent } from './contact/contact.component';
import { BookComponent } from './book/book.component';
import { BookOverviewComponent } from './book-overview/book-overview.component';
import { WhyOverviewComponent } from './why-overview/why-overview.component';
import { WhyComponent } from './why/why.component';
import { ReviewComponent } from './review/review.component';
import { ReviewOverviewComponent } from './review-overview/review-overview.component';
import { BlogComponent } from './blog/blog.component';
import { BlogOverviewComponent } from './blog-overview/blog-overview.component';
import { BlogHomeSectionComponent } from './blog-home-section/blog-home-section.component';
import { ReviewHomeSectionComponent } from './review-home-section/review-home-section.component';
import { TourHomeSectionComponent } from './tour-home-section/tour-home-section.component';
import { WhyHomeSectionComponent } from './why-home-section/why-home-section.component';
import { BookHomeSectionComponent } from './book-home-section/book-home-section.component';
import { AccommodationHomeSectionComponent } from './accommodation-home-section/accommodation-home-section.component';
import { HighlightHomeSectionComponent } from './highlight-home-section/highlight-home-section.component';
import { ImageHomeSectionComponent } from './image-home-section/image-home-section.component';

import { ContextService } from './context.service';
import { AppConfigService } from './app-config.service';

import { QuestionComponent } from './question/question.component';
import { QuestionOverviewComponent } from './question-overview/question-overview.component';
import { QuestionHomeSectionComponent } from './question-home-section/question-home-section.component';
import { ContentHomeSectionComponent } from './content-home-section/content-home-section.component';
import { ContentComponent } from './content/content.component';
import { ContentOverviewComponent } from './content-overview/content-overview.component';
import { TagOverviewComponent } from './tag-overview/tag-overview.component';
import { TagHomeSectionComponent } from './tag-home-section/tag-home-section.component';
import { TagComponent } from './tag/tag.component';
import { FormComponent } from './form/form.component';
import { FormOverviewComponent } from './form-overview/form-overview.component';
import { FormHomeSectionComponent } from './form-home-section/form-home-section.component';
import { ContactFormComponent } from './contact-form/contact-form.component';

//import { MapHomeSectionComponent } from './map-home-section/map-home-section.component';
//import { AgmCoreModule, LazyMapsAPILoaderConfigLiteral } from '@agm/core';

import { CookieService } from 'ngx-cookie-service';


import { HighlightTileComponent } from './highlight-tile/highlight-tile.component';
import { WhyTileComponent } from './why-tile/why-tile.component';
import { QuestionTileComponent } from './question-tile/question-tile.component';
import { GlobalMenuComponent } from './global-menu/global-menu.component';

import { GlobalFooterComponent } from './global-footer/global-footer.component';

import { GlobalCookieComponent } from './global-cookie/global-cookie.component';
import { GlobalBreadcrumbComponent } from './global-breadcrumb/global-breadcrumb.component';
import { RouteReuseStrategy } from '@angular/router';
import { GlobalReuseStrategy } from './global-reuse-strategy';
import { TourTileComponent } from './tour-tile/tour-tile.component';
import { GlobalNotFoundComponent } from './global-not-found/global-not-found.component';





@NgModule({
  declarations: [
    AppComponent,
    TourComponent,
    HighlightComponent,
    HomeComponent,
    AccommodationComponent,
    ImageComponent,
    TourOverviewComponent,
    HighlightOverviewComponent,
    AccommodationOverviewComponent,
    ImageOverviewComponent,
    //ContactComponent,
    BookComponent,
    BookOverviewComponent,
    WhyOverviewComponent,
    WhyComponent,
    ReviewComponent,
    ReviewOverviewComponent,
    BlogComponent,
    BlogOverviewComponent,
    BlogHomeSectionComponent,
    ReviewHomeSectionComponent,
    TourHomeSectionComponent,
    WhyHomeSectionComponent,
    BookHomeSectionComponent,
    AccommodationHomeSectionComponent,
    HighlightHomeSectionComponent,
    ImageHomeSectionComponent,
    QuestionComponent,
    QuestionOverviewComponent,
    QuestionHomeSectionComponent,
    ContentHomeSectionComponent,
    ContentComponent,
    ContentOverviewComponent,
    TagOverviewComponent,
    TagHomeSectionComponent,
    TagComponent,
    FormComponent,
    FormOverviewComponent,
    FormHomeSectionComponent,
    ContactFormComponent,
    //MapHomeSectionComponent,
    HighlightTileComponent,
    WhyTileComponent,
    QuestionTileComponent,
    GlobalMenuComponent,
    GlobalFooterComponent,
    GlobalCookieComponent,
    GlobalBreadcrumbComponent,
    TourTileComponent,
    GlobalNotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,

    FormsModule,
    ReactiveFormsModule,

    // AgmCoreModule.forRoot({
    //  apiKey: 'AIzaSyDWyEFOv4jFe5aqiPr7mZV4uCooNEVqHt4'
    // }),

    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyBe-KPIauM3ZoJLA-qfX_edf649qH2Ms1s'
    // })
    BrowserAnimationsModule
  ],
  providers: [
    provideHttpClient(),
    ContextService,
    AppConfigService,
    //{
        //provide: Greeter,
        //useClass: MeanGreeter // <--- Defining the swappable implementation.
    //}.
    UntypedFormBuilder,
    //AgmCoreModule
    CookieService,
    Meta,
    {provide: RouteReuseStrategy, useClass: GlobalReuseStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
