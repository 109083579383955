import { Component, Input, OnInit } from '@angular/core';
import { Tour } from '../tour';

@Component({
  selector: 'app-tour-tile',
  templateUrl: './tour-tile.component.html',
  styleUrls: ['./tour-tile.component.css']
})
export class TourTileComponent implements OnInit {

  @Input()
  public model : Tour

  constructor() { }

  ngOnInit(): void {
  }

}
