import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalHeaderService } from '../global-header.service';
import { ContextService } from '../context.service';
import { Home } from '../home';
//import { BookHomeSectionComponent } from '../book-home-section/book-home-section.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();

  public model: Home;

  constructor(
    private contextService: ContextService,
    private headerService: GlobalHeaderService) 
  { 
  }
  
  ngOnInit() {

    var subscription = this.contextService.getHome().subscribe(data => {
      this.model = data;
      this.headerService.setHead(this.model);
    });

    this.subscriptions.add(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}