import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalHeaderService } from '../global-header.service';
import { ContextService } from '../context.service';
import { Tag } from '../tag';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.css']
})
export class TagComponent implements OnInit, OnDestroy {
  
  private subscriptions = new Subscription();

  model : Tag

  constructor(
    private route: ActivatedRoute,
    private contextService: ContextService,
    private headerService: GlobalHeaderService ) {
  }

  ngOnInit() {
    var subscription = this.route.paramMap.subscribe(params => {
      
      var name = this.route.snapshot.paramMap.get("name");

      var itemsSubscription = this.contextService.getTag(name).subscribe(data => {
        this.model = data;
        this.headerService.setHead(this.model);
      });
      
      this.subscriptions.add(itemsSubscription);
    });
    
    this.subscriptions.add(subscription);
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
