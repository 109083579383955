import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalHeaderService } from '../global-header.service';
import { ContextService } from '../context.service';
import { ReviewOverview } from '../review-overview';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-review-overview',
  templateUrl: './review-overview.component.html',
  styleUrls: ['./review-overview.component.less']
})
export class ReviewOverviewComponent implements OnInit, OnDestroy {
  
  private subscriptions = new Subscription();

  model : ReviewOverview
  
  constructor(
    private route: ActivatedRoute,
    private contextService: ContextService,
    private headerService: GlobalHeaderService ) {
  }

  ngOnInit() {
    this.model = new ReviewOverview();

    var subscription = this.route.paramMap.subscribe(params => {
      var itemsSubscription = this.contextService.getReviews().subscribe(data => {
        this.model.items = data;
        this.headerService.setHead(this.model);
      });
      
      this.subscriptions.add(itemsSubscription);
    });
    
    this.subscriptions.add(subscription);
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
