import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalHeaderService } from '../global-header.service';
import { ContextService } from '../context.service';
import { Review } from '../review';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-review-home-section',
  templateUrl: './review-home-section.component.html',
  styleUrls: ['./review-home-section.component.less']
})
export class ReviewHomeSectionComponent implements OnInit, OnDestroy {
  
  private subscriptions = new Subscription();

  public model : Review[];

  public activeIndex : number;

  constructor(
    private contextService: ContextService) {
  }

  ngOnInit() {
    var subscription = this.contextService.getReviews().subscribe(data => {
      this.model = data;
    });

    this.activeIndex = 0;
    
    this.subscriptions.add(subscription);
  }

  public previous(event : MouseEvent) : void
  {
    var lastIndex = this.model.length - 1;

    if(this.activeIndex == 0)
    {
      this.activeIndex = lastIndex;
    }
    else
    {
      this.activeIndex = this.activeIndex -1;
    }

    event.preventDefault();
  }

  public next(event : MouseEvent) : void
  {    
    var lastIndex = this.model.length - 1;

    if(this.activeIndex == lastIndex)
    {
      this.activeIndex = 0;
    }
    else
    {
      this.activeIndex = this.activeIndex + 1;
    }

    event.preventDefault();
  }

  public switch(index : number) : void 
  {
    this.activeIndex = index; 
  }

  public isActive (index : number) : boolean 
  {
    return (this.activeIndex == index);
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}