<app-global-cookie></app-global-cookie>
<app-global-menu></app-global-menu>
<app-global-breadcrumb></app-global-breadcrumb>

<div class="n-section-light n-section-content-one">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <div class="n-list-box-header">
          <h1>Highlights</h1>
          <p>What discoveries are waiting to be made.</p>
        </div>
      </div>
    </div>
    
    <div class="row n-list-narrow-gutter">
      <div *ngFor="let item of model.items" class="col-6 col-sm-4 col-md-3">
        <app-highlight-tile [model]="item"></app-highlight-tile>
      </div>
    </div>
  </div>
</div>

<app-global-footer></app-global-footer>