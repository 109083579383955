<app-global-cookie></app-global-cookie>
<app-global-menu></app-global-menu>
<app-global-breadcrumb></app-global-breadcrumb>

<div class="n-section-light n-section-content-one">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <div class="n-list-box-header">
          <h1>{{model!.title}}</h1>
          <p>Pictures we have capered on tour.</p>
        </div>
      </div>
    </div>

    <div class="row">

      <div *ngFor="let item of model.items" class="col-md-4">
        <div class="n-list-box">
          <div class="n-list-box-content">
            <h2>{{item!.title}}</h2>
            <p *ngFor="let text of item.summary">
                {{text}}
            </p>
            <p>
              <a [routerLink]="['/images', item.name]">{{item.linkText}}</a>
            </p>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>

<app-global-footer></app-global-footer>