import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalHeaderService } from '../global-header.service';
import { ContextService } from '../context.service';
import { Content } from '../content';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-content-home-section',
  templateUrl: './content-home-section.component.html',
  styleUrls: ['./content-home-section.component.css']
})
export class ContentHomeSectionComponent implements OnInit, OnDestroy {
  
  private subscriptions = new Subscription();

  public model : Content[]

  constructor(
    private contextService: ContextService) {
  }
  
  ngOnInit(): void {
    var subscription = this.contextService.getContents().subscribe(data => {
      this.model = data;
    });
    
    this.subscriptions.add(subscription);
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
