import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalHeaderService } from '../global-header.service';
import { ContextService } from '../context.service';
import { Highlight } from '../highlight';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-highlight-home-section',
  templateUrl: './highlight-home-section.component.html',
  styleUrls: ['./highlight-home-section.component.less']
})
export class HighlightHomeSectionComponent implements OnInit, OnDestroy {
  
  private subscriptions = new Subscription();

  public model : Highlight[]

  constructor(
    private contextService: ContextService) {
  }

  ngOnInit() {
    var subscription = this.contextService.getHighlights().subscribe(data => {
      this.model = data;
    });
    
    this.subscriptions.add(subscription);
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
