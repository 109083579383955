import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
//import { Observable } from 'rxjs/Observable';
import { Observable, BehaviorSubject } from 'rxjs';

import { Home } from './home';
import { AccommodationOverview } from './accommodation-overview';
import { Accommodation } from './accommodation';
import { HighlightOverview } from './highlight-overview';
import { Highlight } from './highlight';
import { ImageOverview } from './image-overview';
import { Image } from './image';
import { TourOverview } from './tour-overview';
import { Tour } from './tour';
import { Why } from './why';
import { Content } from './content';
import { Review } from './review';
import { Question } from './question';
import { Tag } from './tag';
import { Blog } from './blog';
import { Book } from './book';
import { Form } from './form';
import { Site } from './site';
//import { IContextService } from 'context.service.ts';

@Injectable()
export class ContextService implements OnInit {

  private home : BehaviorSubject<Home>;
  public home$: Observable<Home>;

  private site : BehaviorSubject<Site>;
  public site$: Observable<Site>;

  private accommodationSet : Accommodation[];
  private accommodation : BehaviorSubject<Accommodation>;
  public accommodation$: Observable<Accommodation>;
  private accommodations : BehaviorSubject<Accommodation[]>;
  public accommodations$: Observable<Accommodation[]>;

  private blogSet : Blog[];
  private blog : BehaviorSubject<Blog>;
  public blog$: Observable<Blog>;
  private blogs : BehaviorSubject<Blog[]>;
  public blogs$: Observable<Blog[]>;

  private bookSet : Book[];
  private book : BehaviorSubject<Book>;
  public book$: Observable<Book>;
  private books : BehaviorSubject<Book[]>;
  public books$: Observable<Book[]>;
    
  private contentSet : Content[];
  private content : BehaviorSubject<Content>;
  public content$: Observable<Content>;
  private contents : BehaviorSubject<Content[]>;
  public contents$: Observable<Content[]>
  
  private formSet : Form[];
  private form : BehaviorSubject<Form>;
  public form$: Observable<Form>;
  private forms : BehaviorSubject<Form[]>;
  public forms$: Observable<Form[]>;

  private highlightSet : Highlight[];
  private highlight : BehaviorSubject<Highlight>;
  public highlight$: Observable<Highlight>;
  private highlights : BehaviorSubject<Highlight[]>;
  public highlights$: Observable<Highlight[]>;

  private imageSet : Image[];
  private image : BehaviorSubject<Image>;
  public image$: Observable<Image>;
  private images : BehaviorSubject<Image[]>;
  public images$: Observable<Image[]>;

  private questionSet : Question[];
  private question : BehaviorSubject<Question>;
  public question$: Observable<Question>;
  private questions : BehaviorSubject<Question[]>;
  public questions$: Observable<Question[]>;

  private reviewSet : Review[];
  private review : BehaviorSubject<Review>;
  public review$: Observable<Review>;
  private reviews : BehaviorSubject<Review[]>;
  public reviews$: Observable<Review[]>;

  private tagSet : Tag[];
  private tag : BehaviorSubject<Tag>;
  public tag$: Observable<Tag>;
  private tags : BehaviorSubject<Tag[]>;
  public tags$: Observable<Tag[]>;

  private tourSet : Tour[];
  private tour : BehaviorSubject<Tour>;
  public tour$: Observable<Tour>;
  private tours : BehaviorSubject<Tour[]>;
  public tours$: Observable<Tour[]>;

  private whySet : Why[];
  private why : BehaviorSubject<Why>;
  public why$: Observable<Why>;
  private whys : BehaviorSubject<Why[]>;
  public whys$: Observable<Why[]>;

  constructor(private httpClient:HttpClient) 
  { 
    this.home = new BehaviorSubject<Home>(null);
    this.home$ = this.home.asObservable();

    this.site = new BehaviorSubject<Site>(null);
    this.site$ = this.site.asObservable();

    this.accommodation = new BehaviorSubject<Accommodation>(null);
    this.accommodation$ = this.accommodation.asObservable();
    this.accommodations = new BehaviorSubject<Accommodation[]>(null);
    this.accommodations$ = this.accommodations.asObservable();

    this.blog = new BehaviorSubject<Blog>(null);
    this.blog$ = this.blog.asObservable();
    this.blogs = new BehaviorSubject<Blog[]>(null);
    this.blogs$ = this.blogs.asObservable();
      
    this.book = new BehaviorSubject<Book>(null);
    this.book$ = this.blog.asObservable();
    this.books = new BehaviorSubject<Book[]>(null);
    this.books$ = this.blogs.asObservable();

    this.content = new BehaviorSubject<Content>(null);
    this.content$ = this.content.asObservable();
    this.contents = new BehaviorSubject<Content[]>(null);
    this.contents$ = this.contents.asObservable();

    this.form = new BehaviorSubject<Form>(null);
    this.form$ = this.content.asObservable();
    this.forms = new BehaviorSubject<Form[]>(null);
    this.forms$ = this.contents.asObservable();

    this.highlight = new BehaviorSubject<Highlight>(null);
    this.highlight$ = this.highlight.asObservable();
    this.highlights = new BehaviorSubject<Highlight[]>(null);
    this.highlights$ = this.highlights.asObservable();
  
    this.image = new BehaviorSubject<Image>(null);
    this.image$ = this.image.asObservable();
    this.images = new BehaviorSubject<Image[]>(null);
    this.images$ = this.images.asObservable();
  
    this.question = new BehaviorSubject<Question>(null);
    this.question$ = this.question.asObservable();
    this.questions = new BehaviorSubject<Question[]>(null);
    this.questions$ = this.questions.asObservable();
  
    this.review = new BehaviorSubject<Review>(null);
    this.review$ = this.review.asObservable();
    this.reviews = new BehaviorSubject<Review[]>(null);
    this.reviews$ = this.reviews.asObservable();

    this.tag = new BehaviorSubject<Tag>(null);
    this.tag$ = this.tag.asObservable();
    this.tags = new BehaviorSubject<Tag[]>(null);
    this.tags$ = this.tags.asObservable();
  
    this.tour = new BehaviorSubject<Tour>(null);
    this.tour$ = this.tour.asObservable();
    this.tours = new BehaviorSubject<Tour[]>(null);
    this.tours$ = this.tours.asObservable();
  
    this.why = new BehaviorSubject<Why>(null);
    this.why$ = this.why.asObservable();
    this.whys = new BehaviorSubject<Why[]>(null);
    this.whys$ = this.whys.asObservable();

    //Accommodation
    this.accommodationSet = [];

    //Blog
    var covidReadyBlog : Blog = new Blog();
    covidReadyBlog.name = "covid-certification-arrived";
    covidReadyBlog.title = "Covid 19 Certification Arrived";
    covidReadyBlog.linkText = "Covid 19 Certification Arrived";
    covidReadyBlog.description = "Covid 19 Certification Arrived information";
    covidReadyBlog.summary = [
      "Covid 19 Certification arrived today, In recognition that we follow government and industry COVID-19 guidelines.",
      "Ensuring processes are in place to maintain cleanliness and aid social/physical distancing."
    ];

    this.blogSet = [covidReadyBlog];

    //Book
    this.bookSet = [];

    //Content
    var about : Content = new Content();
    about.name = "about";
    about.title = "About Us";
    about.linkText = "About Us";
    about.description = "Who are Skye Scenic Tours and how we offer reasonably priced tours of the highlights of the Isle of Skye.";
    about.summary = [
      "Who are Skye Scenic Tours how we offer reasonably priced tours of the highlights of the Isle of Skye."
    ];
    about.content = [
      { tag :'p', text: 'Skye Scenic Tours was set up by Donald & Clare Nicolson in 2011, with the sole purpose of offering a reasonably priced tour of the highlights of the Isle of Skye for visitors without the stress of having to plan and rely on public transport. Of course, we welcome passengers with their own vehicles too.' },
      { tag :'p', text: 'Donald is your driver guide, born and bred on Skye, so you will be sure to have a real experience when touring with him in our clean and comfortable 8 seater minibus. Clare will be on hand to answer any queries or questions you may have when contacting us via our Contact Form.' },
      //{ tag :'p', text: 'We pride ourselves on delivering relaxed and entertaining tours for all ages and abilities. Skye Scenic Tours has consistently been in the top 4 bus tours on the Isle of Skye on the Trip Advisor website, each year gaining a certificate of excellence. We are the longest running local independent tour company offering full day group tours of Skye and 2021 is our 10th anniversary of showing visitors this beautiful Island.' }
      { tag :'p', text: 'We pride ourselves on delivering relaxed and entertaining tours. Skye Scenic Tours has consistently been in the top 5 tours on the Isle of Skye on the Trip Advisor website, each year gaining a certificate of excellence. We are the longest running local independent tour company offering full day group tours of Skye and 2021 was our 10th anniversary of showing visitors this beautiful Island.' }
    
    ];

    var contact : Content = new Content();
    contact.name = "contact";
    contact.title = "Contact Us";
    contact.linkText = "Contact Us";
    contact.description = "Contact us information details.";
    contact.summary = [
      "Contact us information details."
    ];
    contact.content = [
      { tag: "p", text: "Don't hesitate to contact us we'd be happy to hear from you." }
    ];












    var termsAndConditions : Content = new Content();
    termsAndConditions.name = "terms-and-conditions";
    termsAndConditions.title = "Terms and Conditions";
    termsAndConditions.linkText = "Terms and Conditions";
    termsAndConditions.description = "Skye Scenic Tours Terms and Conditions";
    termsAndConditions.summary = [
      "Skye Scenic Tours Terms and Conditions"
    ];
    termsAndConditions.content = [     
       
      { tag: "p", text: "Acceptance of the conditions detailed below as per Scottish Law and Custom is implied when a booking is made." }
      
      ,{ tag: "p", text: "We do not store your personal and financial information or share it with anyone." }
      
      ,{ tag: "h3", text: "Luggage" }
      ,{ tag: "p", text: "Please limit your luggage to a small suitcase, maximum weight 15kg per person." }
      ,{ tag: "p", text: "We reserve the right not to carry luggage that we believe to be overweight." }
      ,{ tag: "p", text: "Exclusions: You are booking and paying for transportation and the services of a driver/guide only." }
      ,{ tag: "p", text: "Accommodation, meals, refreshments and entry fees are not included." }
      
      ,{ tag: "h3", text: "Accommodation" }
      ,{ tag: "p", text: "The cost of your accommodation is not included." }
      
      ,{ tag: "h3", text: "Booking Confirmation" }
      ,{ tag: "p", text: "Your seat reservation will only be confirmed when full payment is received by Skye Scenic Tours or an appointed agent." }
      
      ,{ tag: "h3", text: "Cancellation Policy" }
      ,{ tag: "p", text: "All Tours: A cancellation more than 48 hours before tour departure 100% refund." }
      ,{ tag: "p", text: "A cancellation less than 48 hours before departure – at our discretion but typically no refund unless the seat(s) can be resold in which case 100% refund." }
      ,{ tag: "p", text: "No refunds are offered if a customer is late for a tour/cannot find the pick-up point." }
      ,{ tag: "p", text: "Check-in is 10 mins before the advertised departure time." }
      ,{ tag: "p", text: "Skye Scenic Tours reserves the right to cancel trips under exceptional circumstances." }
      ,{ tag: "p", text: "In such cases clients will be promptly informed and offered: a transfer to a substitute tour, an alternative tour provided by another tour operator or a full refund of any monies paid to Skye Scenic Tours. Skye Scenic Tours will not be liable for any travel or accommodation costs associated with reaching tour departure point or booked in anticipation of tour returning, even in the event of Skye Scenic Tours cancelling the tour." }
      
      ,{ tag: "h3", text: "Travel Insurance" }
      ,{ tag: "p", text: "Clients should have appropriate and adequate insurance to cover death, personal injury, medical expenses, repatriation expenses, loss of or damage to luggage and contents of same and expenses associated with cancellation or curtailment of a tour." }
      ,{ tag: "p", text: "Each client is responsible for loss of or damage to baggage or personal possessions or consequential loss arising there from. In the event of client illness or client injury then the client must accept responsibility for any medical costs incurred and Skye Scenic Tours shall not be liable to refund any part of the tour cost." }
      
      ,{ tag: "h3", text: "Health and Safety" }
      ,{ tag: "p", text: "Clients are responsible for your own safety when engaged in activities off of the bus. These are carried out at your own risk." }
      ,{ tag: "p", text: "The group must at all times abide by the directions of the Tour Guide." }
      ,{ tag: "p", text: "Skye Scenic Tours reserves the right to terminate a tour if, in the opinion of the company the behavior of a client or client group is, or is likely to be disruptive, dangerous or upsetting to other people or if such client or group have caused or are likely to cause damage to property." }
      ,{ tag: "p", text: "Skye Scenic Tours will not pay any refund or costs incurred by a client in the event that a tour is terminated due to unacceptable behavior." }
      ,{ tag: "p", text: "No smoking in any of our vehicles." }
      
      ,{ tag: "h3", text: "Age policy" }
      ,{ tag: "p", text: "The minimum age for our scheduled tours is 3 years old." }
      ,{ tag: "p", text: "Passengers below the age of 18 must be accompanied by an adult." }
      
      ,{ tag: "h3", text: "Vehicles" }
      ,{ tag: "p", text: "We reserve the right to change the type of vehicle used if the circumstances dictate this is necessary." }
      
      ,{ tag: "h3", text: "Alterations to Itineraries" }
      ,{ tag: "p", text: "We reserve the right to change timetables or tour itineraries due to weather, availability of attractions, roadwork’s or any other situation beyond our control." }   
      ,{ tag: "p", text: "Refunds will not be offered in these circumstances." }
      
      ,{ tag: "h3", text: "Cancellations & Refunds" }
      ,{ tag: "p", text: "Where the passenger cancels a tour reservation, the effective date of cancellation will be the date on which we receive notification by telephone, email or in writing." }
      ,{ tag: "p", text: "Your notice of cancellation must be confirmed (in writing or verbally) as received by Skye Scenic Tours to be effective." }
      
      ,{ tag: "h3", text: "Complaints" }
      ,{ tag: "p", text: "All complaints should be initially directed to your driver/guide who will make every effort to resolve the problem." } 
      ,{ tag: "p", text: "In the event that a complaint cannot be resolved on the day of the tour then it should be made in writing to donald.nicolson@skyescenictours.com" } 
    
    ];

    var privacyPolicy : Content = new Content();
    privacyPolicy.name = "privacy-policy";
    privacyPolicy.title = "Privacy Policy";
    privacyPolicy.linkText = "Privacy Policy";
    privacyPolicy.description = "What data we keep and how we maintain privacy.";
    privacyPolicy.summary = [
      "What data we keep and how we maintain privacy."
    ];
    privacyPolicy.content = [
      
    ];

    var cookiePolicy : Content = new Content();
    cookiePolicy.name = "cookie-policy";
    cookiePolicy.title = "Cookie Policy";
    cookiePolicy.linkText = "Cookie Policy";
    cookiePolicy.description = "What we use cookies for and how we maintain privacy.";
    cookiePolicy.summary = [
      "What we use cookies for and how we maintain privacy."
    ];
    cookiePolicy.content = [
       { tag: "p", text: "Cookies are small text files that websites can place on your computer or mobile device when your visit that uniquely identify your browser or device."}
      ,{ tag: "p", text: "Cookies allow a websites to know if your computer or device has visited wbsite before. Cookies can then be used to help understand how the site or service is being used, help you navigate between pages efficiently, help remember your preferences, and generally improve your browsing experience. Cookies can also help ensure marketing you see online is more relevant to you and your interests."}
      ,{ tag: "p", text: "There are typically four categories of cookies: Essential, Performance, Functionality and Advertising."}

      ,{ tag: "p", text: "Essential, cookies enable you to move around the website and use its features, such as accessing logged in a, shopping baskets and prefrences."}
      ,{ tag: "p", text: "Performance, cookies which measure how often you visit and how you visit the site."}
      ,{ tag: "p", text: "Functionality, cookies that are used to recognise you and remember your preferences."}
      ,{ tag: "p", text: "Advertising, cookies that are used to collect information about your visit to our site normally shared with 3rd parties."}
    
      ,{ tag: "h2", text: "We only use Essential cookies"}
      ,{ tag: "p", text: "ARRAffinitySameSite	Used to distribute traffic to the website on several servers in order to optimise response times."}
      ,{ tag: "p", text: "ARRAffinity Used to distribute traffic to the website on several servers in order to optimise response time."}
      ,{ tag: "p", text: "_cfduid	Used by the content network, Cloudflare, to identify trusted web traffic."}
      ,{ tag: "p", text: "SstCookiePolicy Used to identify accepted cookie policy."}
    ];

    this.contentSet = [about, contact, termsAndConditions, privacyPolicy, cookiePolicy];

    //Form
    this.formSet = [];

    //Highlight
    var blackCullins : Highlight = new Highlight();
    blackCullins.name = "black-cullins";
    blackCullins.title = "Black Cullins";
    blackCullins.linkText = "Black Cullins";
    blackCullins.description = "Majestic sharp jaggy ridges and peaks, makeup the mountain range across centre of the island.";
    blackCullins.summary = [
      "Majestic sharp jaggy ridges and peaks, makeup the mountain range across centre of the island."
    ];
    blackCullins.thumbnailUrl = "/assets/images/cullins-1-220-4-3.jpg";
    blackCullins.images = [
      "/assets/images/cullins-1-1600-4-3.jpg"
    ];

    var dunBeagBroch : Highlight = new Highlight();
    dunBeagBroch.name = "dun-beag-broch";
    dunBeagBroch.title = "Dun Beag Broch";
    dunBeagBroch.linkText = "Dun Beag Broch";
    dunBeagBroch.description = "Of all of Scotland's Iron Age Brochs, Dun Beag is one of the best preserved. Approximately 2300 years old, this broch consists of a drystone tower with a diameter of around 18.6 metres with walls about 4 metres thick at the base. It currently stands to a maximum height of 2 metres and would once have been an imposing feature in the landscape. Overlooking Loch Bracadale, the views from the top are simply breathtaking.";
    dunBeagBroch.summary = [
      "Of all of Scotland's Iron Age Brochs, Dun Beag is one of the best preserved. Approximately 2300 years old, this broch consists of a drystone tower with a diameter of around 18.6 metres with walls about 4 metres thick at the base. It currently stands to a maximum height of 2 metres and would once have been an imposing feature in the landscape. Overlooking Loch Bracadale, the views from the top are simply breathtaking."
    ];
    dunBeagBroch.thumbnailUrl = "/assets/images/dun-beag-broch-1-220-4-3.jpg";
    dunBeagBroch.images = [
      "/assets/images/dun-beag-broch-1-1600-4-3.jpg"
    ];

    var fairyPools : Highlight = new Highlight();
    fairyPools.name = "fairy-pools";
    fairyPools.title = "Fairy Pools";
    fairyPools.linkText = "Fairy Pools";
    fairyPools.description = "Scenic crystal clear water pools springing from the Black Cullins surrounded by magnificent mountain ridge.";
    fairyPools.summary = [
      "Scenic crystal clear water pools springing from the Black Cullins surrounded by magnificent mountain ridge."
    ];
    fairyPools.thumbnailUrl = "/assets/images/fairy-pools-220-4-3.jpg";
    fairyPools.images = [
      "/assets/images/fairy-pools-1600-4-3.jpg"
    ];

    var fairyGlen : Highlight = new Highlight();
    fairyGlen.name = "fairy-glen";
    fairyGlen.title = "Fairy Glen";
    fairyGlen.linkText = "Fairy Glen";
    fairyGlen.description = "Landscape in miniature which has given way to the legend of it being the Meeting Place of the little people.";
    fairyGlen.summary = [
      "Landscape in miniature which has given way to the legend of it being the Meeting Place of the little people."
    ];
    fairyGlen.thumbnailUrl = "/assets/images/fairy-glen-220-4-3.jpg";
    fairyGlen.images = [
      "/assets/images/fairy-glen-1600-4-3.jpg"
    ];

    var highlandCattle : Highlight = new Highlight();
    highlandCattle.name = "highland-cattle";
    highlandCattle.title = "Highland Cattle";
    highlandCattle.linkText = "Highland Cattle";
    highlandCattle.description = "Highland Cattle likely to make a surprise appearance on quiet narrow roads.";
    highlandCattle.summary = [
      "Highland Cattle likely to make a surprise appearance on quiet narrow roads."
    ];
    highlandCattle.thumbnailUrl = "/assets/images/highland-1-220-4-3.jpg";
    highlandCattle.images = [
      "/assets/images/highland-1-1600-4-3.jpg"
    ];

    var kiltRock : Highlight = new Highlight();
    kiltRock.name = "kilt-rock";
    kiltRock.title = "Kilt Rock";
    kiltRock.linkText = "Kilt Rock";
    kiltRock.description = "60 meter high kilt shaped Basalt pillar cliffs, overlooking the Sound of Rona.";
    kiltRock.summary = [
      "60 meter high kilt shaped Basalt pillar cliffs, overlooking the Sound of Rona."
    ];
    kiltRock.thumbnailUrl = "/assets/images/kilt-rock-220-4-3.jpg";
    kiltRock.images = [
      "/assets/images/kilt-rock-1600-4-3.jpg"
    ];

    //Highlight
    var portree : Highlight = new Highlight();
    portree.name = "portree";
    portree.title = "Portree";
    portree.linkText = "Portree";
    portree.description = "Main village in island with Shops, Banks and picturesque harbour.";
    portree.summary = [
      "Main village in island with Shops, Banks and picturesque harbour."
    ];
    portree.thumbnailUrl = "/assets/images/portree-220-4-3.jpg";
    portree.images = [
      "/assets/images/portree-1600-4-3.jpg"
    ];
      
    var quiraing : Highlight = new Highlight();
    quiraing.name = "quiraing";
    quiraing.title = "Quiraing";
    quiraing.linkText = "Quiraing";
    quiraing.description = "Striking landslip with numerous rock formations, part of the Trotternish ridge.";
    quiraing.summary = [
      "Striking landslip with numerous rock formations, part of the Trotternish ridge."
    ];
    quiraing.thumbnailUrl = "/assets/images/quiraing-1-220-4-3.jpg";
    quiraing.images = [
      "/assets/images/quiraing-1-1600-4-3.jpg"
    ];

    var redCullins : Highlight = new Highlight();
    redCullins.name = "red-cullins";
    redCullins.title = "Red Cullins";
    redCullins.linkText = "Red Cullins";
    redCullins.description = "Granite rounded red hills by side of black cullins.";
    redCullins.summary = [
      "Granite rounded red hills by side of black cullins."
    ];
    redCullins.thumbnailUrl = "/assets/images/redcullins-1-220-4-3.jpg";
    redCullins.images = [
      "/assets/images/redcullins-1-1600-4-3.jpg"
    ];
    
    var sligachanBridge : Highlight = new Highlight();
    sligachanBridge.name = "sligachan-bridge";
    sligachanBridge.title = "Sligachan Bridge";
    sligachanBridge.linkText = "Sligachan Bridge";
    sligachanBridge.description = "Picturesque historic bridge gateway to Cullins.";
    sligachanBridge.summary = [
      "Picturesque historic bridge gateway to Cullins."
    ];
    sligachanBridge.thumbnailUrl = "/assets/images/sligachan-bridge-220-4-3.jpg";
    sligachanBridge.images = [
      "/assets/images/sligachan-bridge-1600-4-3.jpg"
    ];

    var theOldManOfStorr : Highlight = new Highlight();
    theOldManOfStorr.name = "old-man-of-storr";
    theOldManOfStorr.title = "The Old Man of Storr";
    theOldManOfStorr.linkText = "The Old Man of Storr";
    theOldManOfStorr.description = "Spectacular and iconic pinnacle rock formation overlooking the Storr Lochs and the Sound of Raasay.";
    theOldManOfStorr.summary = [
      "Spectacular and iconic pinnacle rock formation overlooking the Storr Lochs and the Sound of Raasay."
    ];
    theOldManOfStorr.thumbnailUrl = "/assets/images/storr-220-4-3.jpg";
    theOldManOfStorr.images = [
      "/assets/images/storr-1600-4-3.jpg"
    ];

    // var neistPoint : Highlight = new Highlight();
    // neistPoint.name = "neist-point";
    // neistPoint.title = "Neist Point";
    // neistPoint.linkText = "Neist Point";
    // neistPoint.description = "Neist Point most western point on Isle of Skye jagged clifs and Lighthouse one of the most scenic in Scotland.";
    // neistPoint.summary = [
    //   "Most western point on Isle of Skye jagged clifs and Lighthouse one of the most scenic in Scotland."
    // ];
    // neistPoint.thumbnailUrl = "/assets/images/neist-1-220-4-3.jpg";
    // neistPoint.images = [
    //   "/assets/images/neist-1-1600-4-3.jpg"
    // ];

    this.highlightSet = [
      blackCullins,
      dunBeagBroch,
      fairyPools,
      fairyGlen,
      highlandCattle,
      kiltRock,
      //neistPoint,
      portree,
      quiraing,
      redCullins,
      sligachanBridge,
      theOldManOfStorr];

    //Image
    this.imageSet = [];

    //Question
    var where : Question = new Question();
    where.name = "where";
    where.title = "Where do the tours start from?";
    where.linkText = "Where";
    where.summary = [
      //"The Grand Tour starts at around 9am from your accommodation in Portree.",
      //"Skye In A Day will depart from Somerled Square at 9am."
      //"We meet at the War Memorial in Portree Somerled Square at 8.50am."
      "We depart from the Portree Visit Scotland iCentre Car Park at 8.35am."
    ];

    var who : Question = new Question();
    who.name = "who";
    who.title = "Who are your tour guides?";
    who.linkText = "Who";
    who.summary = [ 
      "Donald Nicolson"
    ];

    var cost : Question = new Question();
    cost.name = "cost";
    cost.title = "How much do the tours cost?";
    cost.linkText = "Cost";
    cost.summary = [
      "Skye In A Day group tour costs £75 per person.",
      "Private tour cost is £450 for up to 8 hours for a maximum of 7 passengers starting from the Portree/north of Skye areas. Please use the contact form for your private tour enquiry giving your chosen date and number of passengers so that we can check availability."
    ];

    var lunch : Question = new Question();
    lunch.name = "lunch";
    lunch.title = "Is there a lunch stop?";
    lunch.linkText = "Lunch";
    lunch.summary = [
      //"Please bring a packed lunch to eat enroute for our Skye In A Day tour and Grand Tour as there is no lunch stop included on either itinerary."
      //"Please bring a packed lunch to eat enroute for our Skye In A Day tour as there is no lunch stop included on either itinerary."
      "Please bring a packed lunch to eat enroute for our Skye In A Day tour as there is no lunch stop in the itinerary."
    ];

    var book : Question = new Question();
    book.name = "book";
    book.title = "How can I book?";
    book.linkText = "Book";
    book.summary = [
      "Our group tour can be booked online using our live online booking system powered by Checkfront."
    ];

    var other : Question = new Question();
    other.name = "other";
    other.title = "What if I have a specific question regarding a tour?";
    other.linkText = "Other";
    other.summary = [
      "If you have any questions regarding our tours please contact us by using our contact form (below) or by phone 01470 552771."
    ];

    this.questionSet = [where, who, cost, lunch, book, other];

    //Review
    var martina : Review = new Review();
    martina.name = "martina";
    martina.title = "Martina";
    martina.linkText = "Martina";
    martina.who = "Martina";
    martina.source = "Trip Advisor";
    martina.description = "What Martina said about us.";
    martina.summary = [
      "We had the most fantastic day thanks to our guide Donald. The Tour was everything we had hoped for - and even more.",
      "We can highly recommend Skye Scenic Tours"
    ];
    
    var rick : Review = new Review();
    rick.name = "rick";
    rick.title = "Rick";
    rick.linkText = "Rick";
    rick.who = "Rick";
    rick.source = "Trip Advisor";
    rick.description = "What Rick said about us.";
    rick.summary = [
      "Our guide/owner was funny, as well as, knowledgeable about the Isle of Skye. It helps to be from there. We spent 8 hours exploring the Isle, while looking for the sun!",
      "A great day that gave you breathtaking views and the taste of Scottish whiskey."
    ];

    var belinda : Review = new Review();
    belinda.name = "belinda";
    belinda.title = "Belinda";
    belinda.linkText = "Belinda";
    belinda.who = "Belinda";
    belinda.source = "Trip Advisor";
    belinda.description = "What Belinda said about us.";
    belinda.summary = [
      "Donny, our guide, was as delightful as the scenery. He was great craic and, even better, so very knowledgeable. He showed us all about this extraordinary, indescribable island and kept up a jolly and erudite patter all the while.",
      "Do yourself a favor and make sure that it is he who shows you this astonishing place."
    ];

    var cara : Review = new Review();
    cara.name = "cara";
    cara.title = "Cara";
    cara.linkText = "Cara";
    cara.who = "Cara";
    cara.source = "Trip Advisor";
    cara.description = "What Cara said about us.";
    cara.summary = [
      "Had a tour with Donald this past July and it couldn’t of been a better day! I don’t drive myself and really enjoyed seeing the island without the worry of public transport.", 
      "My favourite part was the Fairy Pools, you’d really have to see it to believe it! Honestly, fabulous day!!"
    ];





    this.reviewSet = [martina, rick, belinda, cara];

    //Tag
    this.tagSet = []






















































    //Highlight
    // var portreeHighlight : Highlight = new Highlight();
    // portreeHighlight.name = "portree";
    // portreeHighlight.title = "Portree";
    // portreeHighlight.linkText = "Portree";
    // portreeHighlight.description = "Main village in island with Shops, Banks and picturesque harbour.";
    // portreeHighlight.thumbnailUrl = "/assets/images/portree-220-4-3.jpg";

    // var blackCullinsHighlight : Highlight = new Highlight();
    // blackCullinsHighlight.name = "black-cullins";
    // blackCullinsHighlight.title = "Black Cullins";
    // blackCullinsHighlight.linkText = "Black Cullins";
    // blackCullinsHighlight.description = "Majestic sharp jaggy ridges and peaks, makeup the mountain range across centre of the island.";
    // blackCullinsHighlight.thumbnailUrl = "/assets/images/cullins-1-220-4-3.jpg";

    // var fairyPoolsHighlight : Highlight = new Highlight();
    // fairyPoolsHighlight.name = "fairy-pools";
    // fairyPoolsHighlight.title = "Fairy Pools";
    // fairyPoolsHighlight.linkText = "Fairy Pools";
    // fairyPoolsHighlight.description = "";
    // fairyPoolsHighlight.thumbnailUrl = "/assets/images/fairy-pools-220-4-3.jpg";

    // var fairyGlenHighlight : Highlight = new Highlight();
    // fairyGlenHighlight.name = "fairy-glen";
    // fairyGlenHighlight.title = "Fairy Glen";
    // fairyGlenHighlight.linkText = "Fairy Glen";
    // fairyGlenHighlight.description = "Landscape in miniature which has given way to the legend of it being the Meeting Place of the little people.";
    // fairyGlenHighlight.thumbnailUrl = "/assets/images/fairy-glen-220-4-3.jpg";

    // var highlandCattleHighlight : Highlight = new Highlight();
    // highlandCattleHighlight.name = "highland-cattle";
    // highlandCattleHighlight.title = "Highland Cattle";
    // highlandCattleHighlight.linkText = "Highland Cattle";
    // highlandCattleHighlight.description = "";
    // highlandCattleHighlight.thumbnailUrl = "/assets/images/highland-1-220-4-3.jpg";

    // var kiltRockHighlight : Highlight = new Highlight();
    // kiltRockHighlight.name = "kilt-rock";
    // kiltRockHighlight.title = "Kilt Rock";
    // kiltRockHighlight.linkText = "Kilt Rock";
    // kiltRockHighlight.description = "60 meter high kilt shaped Basalt pillar cliffs, overlooking the Sound of Rona.";
    // kiltRockHighlight.thumbnailUrl = "/assets/images/kilt-rock-220-4-3.jpg";

    // var quiraingHighlight : Highlight = new Highlight();
    // quiraingHighlight.name = "quiraing";
    // quiraingHighlight.title = "Quiraing";
    // quiraingHighlight.linkText = "Quiraing";
    // quiraingHighlight.description = "Striking landslip with numerous rock formations, part of the Trotternish ridge.";
    // quiraingHighlight.thumbnailUrl = "/assets/images/quiraing-1-220-4-3.jpg";

    // var redCullinsHighlight : Highlight = new Highlight();
    // redCullinsHighlight.name = "red-cullins";
    // redCullinsHighlight.title = "Red Cullins";
    // redCullinsHighlight.linkText = "Red Cullins";
    // redCullinsHighlight.description = "";
    // redCullinsHighlight.thumbnailUrl = "/assets/images/redcullins-1-220-4-3.jpg";
    
    // var sligachanBridgeHighlight : Highlight = new Highlight();
    // sligachanBridgeHighlight.name = "sligachan-bridge";
    // sligachanBridgeHighlight.title = "Sligachan Bridge";
    // sligachanBridgeHighlight.linkText = "Sligachan Bridge";
    // sligachanBridgeHighlight.description = "With its stunning backdrop of the Black Cuillins, visit Telford's picturesque old Bridge spanning the Sligachan River and see the Sligachan Monument honouring the pioneering Mountaineers Norman Collie and John MacKenzie";
    // sligachanBridgeHighlight.thumbnailUrl = "/assets/images/sligachan-bridge-220-4-3.jpg";

    // var theOldManOfStorrHighlight : Highlight = new Highlight();
    // theOldManOfStorrHighlight.name = "old-man-of-storr";
    // theOldManOfStorrHighlight.title = "The Old Man of Storr";
    // theOldManOfStorrHighlight.linkText = "The Old Man of Storr";
    // theOldManOfStorrHighlight.description = "Spectacular and iconic pinnacle rock formation overlooking the Storr Lochs and the Sound of Raasay.";
    // theOldManOfStorrHighlight.thumbnailUrl = "/assets/images/storr-220-4-3.jpg";

    // var neistPointHighlight : Highlight = new Highlight();
    // neistPointHighlight.name = "neist-point";
    // neistPointHighlight.title = "Neist Point";
    // neistPointHighlight.linkText = "Neist Point";
    // neistPointHighlight.description = "";
    // neistPointHighlight.thumbnailUrl = "/assets/images/neist-1-220-4-3.jpg";

    var day : Tour = new Tour();
    day.name = "skye-in-a-day-tour";
    day.title = "Skye In A Day Tour";
    day.linkText = "Skye In A Day Tour";
    //day.description = "Isle of Skye In A Day Tour. See iconic Isle of Skye sights such as The Fairy Pools, Neist Point, Fairy Glen, The Quiraing, Kilt Rock and more. Walk and take in the splendour of the Fairy Pools, numerous photo stops create magical memories.";
    day.description = "Isle of Skye In A Day Tour. See iconic Isle of Skye sights such as Sligachan, The Fairy Pools, Dun Beag Broch, Fairy Glen, The Quiraing, Kilt Rock and more. Enjoy a moderate hike to take in the splendour of the Fairy Pools as well as other photo stops to create magical memories.";
    day.keywords = "Isle of Skye Day Tour, Group Tour, Departs Portree, Departs North Skye, Old Man of Storr, Quiraing, Fairy Pools";
    day.summary = [
      //"Isle of Skye In A Day Tour. See iconic sights such as The Old Man of Storr, Kilt Rock, The Quiraing, The Fairy Glen, Dunvegan Castle, Neist Point, Dun Beag Broch and the Fairy Pools."
      //"Isle of Skye In A Day Tour. See iconic Isle of Skye sights such as The Fairy Pools, Neist Point, Fairy Glen, The Quiraing, Kilt Rock and more. Walk and take in the spender of the Fairy Pools, numerous photo stops create magical memories"
      //"Isle of Skye In A Day Tour. See iconic Isle of Skye sights such as The Fairy Pools, Neist Point, Fairy Glen, The Quiraing, Kilt Rock and more. Walk and take in the splendour of the Fairy Pools, numerous photo stops create magical memories"
      "Isle of Skye In A Day Tour. See iconic Isle of Skye sights such as Sligachan, The Fairy Pools, Dun Beag Broch, Fairy Glen, The Quiraing, Kilt Rock and more. Enjoy a moderate hike to take in the splendour of the Fairy Pools as well as other photo stops to create magical memories."
    ];
    day.highlights = [
      portree
      ,sligachanBridge
      ,fairyPools
      ,dunBeagBroch
      //,neistPointHighlight
      ,fairyGlen
      ,quiraing
      ,kiltRock
      ,theOldManOfStorr
    ],
    day.points = [
       "Explore the best of Scotland - the Isle of Skye"
      ,"Spacious air conditioned 8 seater bus - no mass groups"
      ,"Meet other travellers"
      ,"Please bring a packed lunch with you onto the tour to eat enroute as there is no cafe lunch stop on this itinerary"
    ];
    day.timeline = [
      "The Fairy Pools (1 hour 15 minute walk from the car park to the pools and back to the car park) - located at Glenbrittle with the backdrop of the menacing Black Cuillin Mountains, the Fairy Pools are crystal clear rock pools fed by a series of waterfalls and well worth the walk to get there."
      ,"Dun Beag Broch - climb to the top of this Iron Age round stone tower only found in Scotland to survey the amazing vista. It stands on a rocky knoll commanding excellent views of the surrounding landscape. The broch would have been built around 2,000–2,500 years ago. It appears to have been occupied for a very long period – medieval and later coins were reportedly recovered from the site in the early 1900s."
      //,"Neist Point - Enjoy a gentle walk up the hill to capture the iconic shot of the Lighthouse at the most westerly headland on Skye. With its stunning cliff scenery, a fine lighthouse, an outlook to the Outer Hebrides and the Minch, it gives the chance to spot marine wildlife (Orcas, Whales and Dolphins to name but a few) in the summer months."
      ,"Dunvegan Castle - Here we will stop for a photograph of the oldest continuously inhabited castle in Scotland which has been the ancestral home of the Chiefs of Clan MacLeod for 800 years."
      ,"Fairy Glen - Climb to the top of Castle Ewan to fully appreciate the strange landscape of the mysterious Fairy Glen. The topography is a miniature landslip of small round-topped grassy hills with lochans (small pools)."
      ,"The Quiraing - The most spectacular and most northerly landslip on the Trotternish Ridge makes for a pleasant stroll to take in the landscape and seascape of Skye over to Mainland Scotland."
      ,"Kilt Rock - This striking rock formation known as Kilt Rock is a 180 foot high sea cliff. The massive columns of dolerite have the appearance of pleats in a tartan kilt & traditionally form the basis of the Skye tartan pattern. This entirely natural phenomenon relates in appearance, although not identically, to the recorded basalt rocks at Staffa near Iona."
      ,"The Old Man of Storr -  The Old Man of Storr rises to 535 metres. Dominating the scene and visible from many more parts of the islands the Storr is the hill immediately west of the Old Man and rising to 719 metres with dramatic cliffs on its eastern flank. We will stop at Loch Fada so that you can capture a shot of the Old Man of Storr in all of its glory."
    ];
    day.pricing = [
      "£75"
    ];
    day.itemId = 10;
    day.images = [
      "/assets/images/storr-1600-4-3.jpg"
    ];
    day.content = [  
      //"From the end of March to the end of October we offer a leisurely 9 hour group tour, suitable for all ages, departing from The War Memorial, Portree Somerled Square bus station at 9am and returning at around 6pm. Depending on where you are staying in the north of Skye, we may be able to offer pick up from your accommodation so please get in touch if you are not staying in central Portree."
      //"From the end of March to the end of October we offer a leisurely 8 hour group tour, suitable for all ages, meeting at The War Memorial, Portree Somerled Square bus station at 8.50am and returning at around 5pm."
      //"From the end of March to the end of October we offer a leisurely 8 hour group tour, meeting at The War Memorial, Portree Somerled Square bus station at 8.50am and returning at around 5pm. This tour is suitable for those with reasonable levels of fitness and mobility."
      //"From March to October we offer a leisurely 8 hour group tour, departing from the Visit Scotland iCentre Car Park at 8.45am and returning at around 4.45pm. Please be there 5 minutes beforehand and make yourself known to your driver guide in the Skye Scenic Tours minibus on arrival. The tour is suitable only for those with reasonable levels of fitness and mobility but due to the set times and itinerary of the tour it is not suitable for cruise ship passengers."
      //"From March to October we offer a leisurely 8 hour group tour, departing from the Portree Visit Scotland iCentre Car Park at 8.45am and returning at around 4.45pm. Please be there 5 minutes beforehand and make yourself known to your driver guide in the Skye Scenic Tours minibus on arrival. The tour is suitable only for those with reasonable levels of fitness and mobility but due to the set times and itinerary of the tour it is not suitable for cruise ship passengers."
      "From March to October we offer a leisurely 8 hour group tour, departing from the Portree Visit Scotland iCentre Car Park at 8.35am and returning at around 4.35pm. Please be there 5 minutes beforehand to make yourself known to your driver guide in the Skye Scenic Tours minibus on arrival and find your seats. The tour is suitable only for those with reasonable levels of fitness and mobility as the walks are not optional. Due to the set times and itinerary of this group tour it is not suitable for cruise ship passengers."
    ];
    day.conditions = [
      "Please bring a packed lunch with you onto the tour to eat enroute as there is no cafe lunch stop on this itinerary."
      ,"We reserve the right to change the itinerary depending on the weather on the day - your driver guide will advise of the time you\'ll have at each location."
    ];

    //Tour
    // var grand : Tour = new Tour();
    // grand.name = "grand-tour";
    // grand.title = "The Grand Tour of Skye - Walking Tour";
    // grand.linkText = "Grand Tour";
    // grand.description = "The Grand Tour of Skye is a small group full day tour visiting the most popular sites on Isle of Skye such as The Quiraing, Neist Point Lighthouse, Fairy Pools and more with Quiraing Walk.";
    // grand.keywords = "Isle of Skye Day Tour, Group Tour, Walking Tour, Departs Portree, Departs North Skye, Old Man of Storr, Quiraing, Neist Point, Fairy Pools";
    // grand.summary = [
    //   "The Grand Tour of Skye is a small group full day tour visiting the most popular sites on Skye such as The Quiraing, Neist Point Lighthouse and more."
    //   //,"Tour seats a maximum of 8 passengers."
    // ];
    // grand.highlights = [
    //   portreeHighlight
    //   ,blackCullinsHighlight
    //   ,fairyPoolsHighlight
    //   ,neistPointHighlight
    //   ,fairyGlenHighlight
    //   ,quiraingHighlight
    //   ,theOldManOfStorrHighlight
    // ],
    // grand.points = [
    //   "Explore the best of Scotland - the Isle of Skye"
    //   ,"Spacious air conditioned 8 seater bus - no mass groups"
    //   ,"Meet other travellers"
    // ];
    // grand.timeline = [
    //    "Pick up from and drop off at your accommodation in the Portree area."
    //   ,"Walk to the famous Fairy Pools - an easy 1 hour 15 minutes."
    //   ,"Visit Neist Point (the most westerly point on Skye) for a walk to capture the iconic photograph of the scenery and lighthouse - an easy 45 minutes."
    //   ,"Walk up to Castle Ewen, the Fairy Fort, and admire the mysterious landscape of the Fairy Glen - an easy 30 minute stop."
    //   ,"Explore the magnificent Quiraing with its unique rock pinnacles and amazing views over the sea to mainland Scotland - a 2 hour hike in the Quiraing."
    // ];
    // grand.pricing = [
    //   "£50"
    // ];
    // grand.itemId = 7;
    // grand.images = [
    //   "/assets/images/storr-1600-4-3.jpg"
    //   ,"/assets/images/cullins-1-1600-4-3.jpg"
    //   ,"/assets/images/cullins-2-1600-4-3.jpg"
    //   ,"/assets/images/flodigarry-1-1600-4-3.jpg"
    //   ,"/assets/images/highland-1-1600-4-3.jpg"
    //   ,"/assets/images/quiraing-1-1600-4-3.jpg"
    //   ,"/assets/images/quiraing-2-1600-4-3.jpg"
    //   //,"/assets/images/quiraing-3-1600-4-3.jpg"
    // ];
    // grand.content = [
    //   //"This 9 hour group tour operates from April until the end of September and is designed for those relatively experienced at hiking (so hiking boots/shoes or sneakers and waterproofs are essential)."
    //   //,"We visit less locations than on the Skye In A Day tour but spend more time allowing you to leisurely explore each place without rushing and include a 2 hour hike in The Quiraing."
    //   //,"This is a small group tour for a maximum of 8 passengers. We pick up from and drop off at your accommodation in the Portree area (starting at around 9am and returning back for around 6pm - individual pick up time will be given via e-mail once booking has been received) and, as there is no lunch stop on this tour, we ask that passengers bring a packed lunch to eat enroute."
    //   //,"There will be coffee and bathroom stops during the tour visiting the following locations:"
    //   "The Grand Tour of Skye is a small group full day tour visiting the most popular sites on Isle of Skye such as The Quiraing, Neist Point Lighthouse, Fairy Pools and more with Quiraing Walk."
    //   ,"This 8 hour group tour operates from April until the end of September and is designed for those relatively experienced at hiking (so hiking boots/shoes or sneakers and waterproofs are essential)."
    //   ,"We visit less locations than on the Skye In A Day tour but spend more time allowing you to leisurely explore each place without rushing and include a 2 hour hike in The Quiraing."
    //   ,"This is a small group tour for a maximum of 8 passengers. We pick up from and drop off at your accommodation in the Portree area (starting at around 9am and returning back for around 5pm - individual pick up time will be given via e-mail once booking has been received) and, as there is no lunch stop on this tour, we ask that passengers bring a packed lunch to eat enroute."
    //   ,"There will be stops for a takeaway coffee and bathroom breaks during the tour visiting the following locations:"
    // ];
    // grand.conditions = [
    //   "Timings given for stops are approximate and will depend on the weather on the day - your driver guide will advise of the time you\'ll have at each location. This tour is suitable only for those fit and able to complete a 2 hour hike of the Quiraing."
    // ];








    












    var bespoke  : Tour = new Tour();
    bespoke.name = "private-tour";
    bespoke.title = "Private Tour";
    bespoke.linkText = "Private Tour";
    //bespoke.description = "Tour the Isle of Skye using your own personal itinerary on a private full day tour. Our experience guides will show you the best places to visit on the Island!";
    bespoke.description = "Tour the Isle of Skye using your own personal itinerary on a private full day tour. Our experience guides will show you the best places to visit on the Island!";
    bespoke.keywords = "Isle of Skye Day Tour, Private Tour";
    
    bespoke.summary = [
      //"Tour the Isle of Skye using your own personal itinerary on a private Isle of Skye tour."
      //,"We work with you to tailor a bespoke itinerary suited to your interests and time."
      //"Tour the Isle of Skye using your own personal itinerary on a private full day tour. Our experience guides will show you the best places to visit on the Island!"
      "Tour the Isle of Skye using your own personal itinerary on a private full day tour. Our experience guides will show you the best places to visit on the Island!"
    ];
    bespoke.highlights = [
      theOldManOfStorr
      ,kiltRock
      ,quiraing
      ,fairyPools
      //,neistPointHighlight
      ,dunBeagBroch
      ,fairyGlen
      ,sligachanBridge
    ],
    bespoke.points = [
       "Explore the best of Scotland - the Isle of Skye in your timeframe"
      ,"Spacious air conditioned 7 seater bus"
      ,"Your familiy, your friends"
    ];
    bespoke.timeline = [
      "Its yours to create with our help of our experience guides."
    ];
    bespoke.pricing = [
      "£450"
    ];
    bespoke.images = [
      "/assets/images/storr-1600-4-3.jpg"
    ];
    bespoke.content = [
      // "Tour the Isle of Skye using your own personal itinerary on a private Isle of Skye tour."
      // ,"We work with you to tailor a bespoke itinerary suited to your interests and possible time we have with you."
      // ,"Private tours are for up to 8 passengers, for up to 9 hours."
      // ,"Private tours start from North Skye."
       "Tour the Isle of Skye using your own personal itinerary on a private Isle of Skye tour."
      ,"We work with you to tailor a bespoke itinerary suited to your interests and possible time we have with you."
      ,"Private tours are for up to 7 passengers, for up to 8 hours."
      ,"Private tours start from North Skye/Portree area."
    ];
    bespoke.conditions = [
      "Our Private Tours are available from the North of Skye."
    ];

    this.tourSet = [day, bespoke];

    blackCullins.tours = [day, bespoke];
    fairyPools.tours = [day, bespoke];
    fairyGlen.tours = [day, bespoke];
    highlandCattle.tours = [day, bespoke];
    kiltRock.tours = [day, bespoke];
    //neistPoint.tours = [day, bespoke];

    dunBeagBroch.tours = [day, bespoke];
    portree.tours = [day, bespoke];
    quiraing.tours = [day, bespoke];
    redCullins.tours = [day, bespoke];
    sligachanBridge.tours = [bespoke];
    theOldManOfStorr.tours = [day, bespoke];

    //Why
    var scenery : Why = new Why();
    scenery.name = "breathtaking-scenery";
    scenery.title = "Breathtaking Scenery";
    scenery.linkText = "Breathtaking Scenery";
    scenery.description = "Discover the many breathtaking views.";
    scenery.icon = "/assets/style/icons8-black/icons8-scenic-view-100.png";

    var reviews : Why = new Why();
    reviews.name = "rated";
    reviews.title = "Excellent Reviews";
    reviews.linkText = "Excellent Reviews";
    reviews.description = "Rated excellent find out what customers thought of our tours.";
    reviews.icon = "/assets/style/icons8-black/icons8-popular-100.png";

    var based : Why = new Why();
    based.name = "skye-based";
    based.title = "Skye Based";
    based.linkText = "Skye Based";
    based.description = "An Isle of Skye based tour company.";
    based.icon = "/assets/style/icons8-black/icons8-marker-100.png";

    var departurs : Why = new Why();
    departurs.name = "tours-depart-from-various-locations-throughout-the-island";
    departurs.title = "Various Departures";
    departurs.linkText = "Various Departures";
    departurs.description = "Tours depart from the north end of Skye.";
    departurs.icon = "/assets/style/icons8-black/icons8-shuttle-100.png";

    this.whySet = [scenery, reviews, departurs, based];
  }

  ngOnInit(){
  }

  getHome(): Observable<Home> {  

    var home = new Home();
    home.name = "";
    home.title= "Isle of Skye Tours";
    home.linkText= "Home";
    home.description = "Group and Private Tours of the Isle of Skye. Discover the many breathtaking views on one of the world's most scenic Islands! Rated excellent. Daily tours depart from the north end of Skye.";
    home.keywords = "Isle of Skye Tour, Day Tours, Tours, Isle of Skye, Skye, Portree, Scotland, Group, Private";
    
    this.home.next(home);
    return this.home$;
  }

  getSite(): Observable<Site> {  

    var site = new Site();
    site.phoneNumber = "01470 552771";
    site.address = [
      "Skye Scenic Tours",
      "Portree",
      "Flodigarry",
      "Isle of Skye",
      "IV51 9HZ"];

    this.site.next(site);
    return this.site$;
  }

  getAccommodation(name: string): Observable<Accommodation> {  
    
    var modelSet = this.accommodationSet;

    for(var i = 0; i < modelSet.length; i++)
    {
      if (modelSet[i].name == name)
      {
        var model = modelSet[i];
        this.accommodation.next(model);
        break;
      }
    }

    return this.accommodation$;
  }

  getAccommodations(): Observable<Accommodation[]> {  

    this.accommodations.next(this.accommodationSet);
    return this.accommodations$;
  }

  getBlog(name: string): Observable<Blog> {  

    var modelSet = this.blogSet;

    for(var i = 0; i < modelSet.length; i++)
    {
      if (modelSet[i].name == name)
      {
        var model = modelSet[i];
        this.blog.next(model);
        break;
      }
    }

    return this.blog$;
  }

  getBlogs(): Observable<Blog[]> {  

    this.blogs.next(this.blogSet);
    return this.blogs$;
  }

  getBook(name: string): Observable<Book> {  

    var modelSet = this.bookSet;

    for(var i = 0; i < modelSet.length; i++)
    {
      if (modelSet[i].name == name)
      {
        var model = modelSet[i];
        this.book.next(model);
        break;
      }
    }

    return this.blog$;
  }

  getBooks(): Observable<Book[]> {  

    this.books.next(this.bookSet);
    return this.blogs$;
  }

  getContent(name: string): Observable<Content> {  

    var modelSet = this.contentSet;

    for(var i = 0; i < modelSet.length; i++)
    {
      if (modelSet[i].name == name)
      {
        var model = modelSet[i];
        this.content.next(model);
        break;
      }
    }

    return this.content$;
  }

  getContents(): Observable<Content[]> {  

    this.contents.next(this.contentSet);
    return this.contents$;
  }

  getForm(name: string): Observable<Form> {  

    var modelSet = this.formSet;

    for(var i = 0; i < modelSet.length; i++)
    {
      if (modelSet[i].name == name)
      {
        var model = modelSet[i];
        this.form.next(model);
        break;
      }
    }

    return this.blog$;
  }

  getForms(): Observable<Form[]> {  
  
    this.forms.next(this.formSet);
    return this.blogs$;
  }

  getHighlight(name: string): Observable<Highlight> {  

    var modelSet = this.highlightSet;

    for(var i = 0; i < modelSet.length; i++)
    {
      if (modelSet[i].name == name)
      {
        var model = modelSet[i];
        this.highlight.next(model);
        break;
      }
    }

    return this.highlight$;
  }

  getHighlights(): Observable<Highlight[]> {  

    var models : Highlight[] = [];
    
    this.highlights.next(this.highlightSet);
    return this.highlights$;
  }

  getImage(name: string): Observable<Image> {  

    var modelSet = this.imageSet;

    for(var i = 0; i < modelSet.length; i++)
    {
      if (modelSet[i].name == name)
      {
        var model = modelSet[i];
        this.image.next(model);
        break;
      }
    }

    return this.image$;
  }

  getImages(): Observable<Image[]> {  

    this.images.next(this.imageSet);
    return this.images$;
  }

  getQuestion(name: string): Observable<Question> {  

    var modelSet = this.questionSet;

    for(var i = 0; i < modelSet.length; i++)
    {
      if (modelSet[i].name == name)
      {
        var model = modelSet[i];
        this.question.next(model);
        break;
      }
    }

    return this.question$;
  }

  getQuestions(): Observable<Question[]> {  
   
    this.questions.next(this.questionSet);
    return this.questions$;
  }
  
  getReview(name: string): Observable<Review> {  

    var modelSet = this.reviewSet;

    for(var i = 0; i < modelSet.length; i++)
    {
      if (modelSet[i].name == name)
      {
        var model = modelSet[i];
        this.review.next(model);
        break;
      }
    }
    
    return this.review$;
  }

  getReviews(): Observable<Review[]> {  

    this.reviews.next(this.reviewSet);
    return this.reviews$;
  }

  getTag(name: string): Observable<Tag> {  

    var modelSet = this.tagSet;

    for(var i = 0; i < modelSet.length; i++)
    {
      if (modelSet[i].name == name)
      {
        var model = modelSet[i];
        this.tag.next(model);
        break;
      }
    }

    return this.tag$;
  }

  getTags(): Observable<Tag[]> {  

    this.tags.next(this.tagSet);
    return this.tags$;
  }

  getTour(name: string): Observable<Tour> { 

    var modelSet = this.tourSet;

    for(var i = 0; i < modelSet.length; i++)
    {
      if (modelSet[i].name == name)
      {
        var model = modelSet[i];
        this.tour.next(model);
        break;
      }
    }

    return this.tour$;
  }

  getTours(): Observable<Tour[]> {  

    this.tours.next(this.tourSet);
    return this.tours$;
  }
  
  getWhy(name: string): Observable<Why> {  

    var modelSet = this.whySet;

    for(var i = 0; i < modelSet.length; i++)
    {
      if (modelSet[i].name == name)
      {
        var model = modelSet[i];
        this.why.next(model);
        break;
      }
    }

    return this.why$;
  }

  getWhys(): Observable<Why[]> { 

    this.whys.next(this.whySet);
    return this.whys$;
  }
}