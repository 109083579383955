import { Page } from "./page";
import { Form } from "./form";

export class FormOverview extends Page 
{
    items : Form[];

    constructor() {
        super();
        this.title = "Forms";
    }
}
