import { Page } from "./page";
import { Review } from "./review";

export class ReviewOverview extends Page 
{
    items : Review[];

    constructor() {
        super();
        this.title = "Reviews";
    }
}
