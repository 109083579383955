import { Page } from "./page";
import { Accommodation } from "./accommodation";

export class AccommodationOverview extends Page
{
    items : Accommodation[];

    constructor() {
        super();
        this.title = "Accommodation";
    }
}