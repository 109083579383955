<div class="n-list-box">
    <div class="n-list-box-content">
        <h2>{{model!.title}}</h2>
        <p *ngFor="let text of model.summary">
            {{text}}
        </p>
        <p>
        <a [routerLink]="['/tours', model.name]">{{model.linkText}}</a>
        <!-- &#8594; -->
        </p>
    </div>
</div>