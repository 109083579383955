import { Page } from "./page";
import { Blog } from "./blog";

export class BlogOverview extends Page 
{
    items : Blog[];

    constructor() {
        super();
        this.title = "Blog";
    }
}
