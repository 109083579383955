import { Page } from "./page";
import { Highlight } from "./highlight";

export class HighlightOverview extends Page
{
    items : Highlight[];

    constructor() {
        super();
        this.title = "Highlights";
    }
}