import { Component, OnInit } from '@angular/core';
import { ContextService } from '../context.service';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, AbstractControl, Validators } from '@angular/forms';
import { FormItem } from '../form-item';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TagHomeSectionComponent } from '../tag-home-section/tag-home-section.component';
import { AppConfigService } from '../app-config.service';
import { AppConfig } from '../app-config';
import { Subscription } from 'rxjs/internal/Subscription';
//import { LocalValidator } from '../contains-validator';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {

  private subscriptions = new Subscription();
  private appConfig = new AppConfig();
  public form: UntypedFormGroup;
  public submitted: boolean;

  constructor(
    private contextService: ContextService,
    private fb: UntypedFormBuilder,
    private appConfigService: AppConfigService,
    private httpClient: HttpClient) { 
  }

  ngOnInit(): void {
    this.form = this.createFormGroup();
    this.submitted  = false;

    var appConfigSubscription = this.appConfigService.get().subscribe(data => {
      this.appConfig = data;
    });

    this.subscriptions.add(appConfigSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get name() : FormItem 
  {
    return new FormItem(this.form.get('name'), 'Name');
  }
  
  get email() : FormItem 
  {
    return new FormItem(this.form.get('email'), 'Email');
  }

  get phone() : FormItem 
  {
    return new FormItem(this.form.get('phone'), 'Phone');
  }
  
  get message() : FormItem 
  {
    return new FormItem(this.form.get('message'), 'Message');
  }

  get signature() : FormItem 
  {
    return new FormItem(this.form.get('signature'), 'Signature');
  }

  createFormGroup() : UntypedFormGroup
  {
      // Each form needs one form group.
      var formGroup = new UntypedFormGroup({
        name: 
          new UntypedFormControl(
            '',
            {
              validators: [
                Validators.required, 
                Validators.minLength(4), 
                Validators.maxLength(128)],
              updateOn: 'blur',
            }),
        email: 
          new UntypedFormControl(
            '',
            {
              validators: [
                Validators.required, 
                Validators.minLength(5), 
                Validators.maxLength(320), 
                Validators.pattern('(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])')],
              updateOn: 'blur',
            }),
        phone: 
          new UntypedFormControl(
            '',
            {
              validators: [
                Validators.minLength(11), 
                Validators.maxLength(17)],
              updateOn: 'blur',
            }),
        message: 
          new UntypedFormControl(
            '',
            {
              validators: [
                Validators.required, 
                Validators.minLength(32)
              ],
              updateOn: 'blur',
            }),
        signature: 
          new UntypedFormControl(
            '',
            {
              validators: [],
              updateOn: 'blur',
            })
        });

      return formGroup;
  }

  isValid() : boolean
  {
    var valid : boolean = false;

    valid = this.name.isValid
      && this.email.isValid
      && this.signature.isValid
      && this.message.isValid;

    return valid;
  }
  
  save() : boolean 
  {
    var valid = this.isValid();

    if (valid)
    {
      
      const url : string = this.appConfig.baseUrl;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };

      this.httpClient.post<any>(url, this.form.value, httpOptions)
        .subscribe({
          next: data => {
          },
          error: error => {
          }
        });
    }
    else
    {
      this.name.control.markAsTouched();
      this.email.control.markAsTouched();
      this.signature.control.markAsTouched();
      this.message.control.markAsTouched();
    }

    this.submitted = valid;

    return this.submitted;
  }
}