<div class="container">
  <div class="row">
    <div class="col-md-8">
      <div class="n-list-box-header">
        <h2>Latest Posts</h2>
        <p>Latest updates.</p>
      </div>
    </div>
    <div class="col-md-4 d-md-flex align-items-center justify-content-end">
      <a [routerLink]="'/blogs'" [routerLinkActive]="'active'">All Posts</a>
    </div>
  </div>
  <div class="row">
    <div *ngFor="let item of model" class="col-md-6">

      <div class="n-list-box">
        <div class="n-list-box-content">
          <h2>{{item!.title}}</h2>
          <p *ngFor="let text of item.summary">
              {{text}}
          </p>
          <p>
            <a [routerLink]="['/blogs', item.name]">{{item.linkText}}</a>
          </p>
        </div>
      </div>
      
    </div>
  </div>
</div>