import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalHeaderService } from '../global-header.service';
import { ContextService } from '../context.service';
import { Accommodation } from '../accommodation';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-accommodation',
  templateUrl: './accommodation.component.html',
  styleUrls: ['./accommodation.component.less']
})
export class AccommodationComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();

  public model : Accommodation
  
  constructor(
    private route: ActivatedRoute,
    private contextService: ContextService,
    private headerService: GlobalHeaderService ) {
  }

  ngOnInit() {
    var subscription = this.route.paramMap.subscribe(params => {

      var name = this.route.snapshot.paramMap.get("name");
      
      var itemsSubscription = this.contextService.getAccommodation(name).subscribe(data => {
        this.model = data;
        this.headerService.setHead(this.model);
      });

      this.subscriptions.add(itemsSubscription);
   });

   this.subscriptions.add(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
