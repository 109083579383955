<app-global-cookie></app-global-cookie>
<app-global-menu></app-global-menu>

<div class="n-section-light n-section-content-one">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <div class="n-list-box-header">
          <h1>{{model!.title}}</h1>
          <p>{{model!.description}}</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">

        <div class="n-list-box-flush">
          <div class="n-list-box-content-flush">

          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<app-global-footer></app-global-footer>