<app-global-cookie></app-global-cookie>
<app-global-menu></app-global-menu>
<app-global-breadcrumb></app-global-breadcrumb>

<div class="n-section-light n-section-content-one">

  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <div class="n-list-box-header">
          <h1>{{model!.title}}</h1>
          <p>{{model!.description}}</p>
        </div>
      </div>
    </div>

    <app-contact-form *ngIf="model.name == 'contact'"></app-contact-form>
  
    <div *ngIf="model.name != 'contact'" class="row">
      <div class="col-12 col-sm-8">

        <div class="n-list-box-flush">
          <div class="n-list-box-content-flush">
            <span *ngFor="let text of model.content">
              <h2 *ngIf="text.tag == 'h2'"> 
                {{text.text}}
              </h2>
              <h3 *ngIf="text.tag == 'h3'">
                {{text.text}}
              </h3>
              <p *ngIf="text.tag == 'p'">
                {{text.text}}
              </p>
            </span>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<app-global-footer></app-global-footer>