<app-global-cookie></app-global-cookie>
<app-global-menu></app-global-menu>

<div class="n-section-home">
  <div class="container">
    <div class="row">
      <div class="col-12 d-md-flex align-items-center text-center">
        <!-- <div class="n-list-box-transparent">
          <div class="n-list-box-transparent-content"> -->
            <h1>Experience <em>Skye</em></h1>     
            <p>Experience one of the world's most scenic Islands!</p>
          <!-- </div>
        </div> -->
      </div>
    </div>
  </div>
</div>

<div class="n-section-why">
  <div class="n-section-content-elevate">
    <app-why-home-section></app-why-home-section>
  </div>
</div>

<div class="n-section-review">
  <div class="n-section-content-one">
    <app-review-home-section></app-review-home-section>
  </div>
</div>

<div class="n-section-tour n-section-light">
  <div class="n-section-content-one">
    <app-tour-home-section></app-tour-home-section>  
  </div>
</div>

<div class="n-section-highlight n-section-lighter">
  <div class="n-section-content-one">
    <app-highlight-home-section></app-highlight-home-section>  
  </div>  
</div>

<div class="n-section-blog n-section-light">
  <div class="n-section-content-one">
    <app-blog-home-section></app-blog-home-section>
  </div>
</div>

<!-- <div class="n-section-map">
  <div class="n-section-content-none">
    <app-map-home-section></app-map-home-section>
  </div>
</div> -->

<div class="n-section-about n-section-lighter">
  <div class="n-section-content-one">
    <app-content-home-section></app-content-home-section>
  </div>
</div>

<div class="n-section-question n-section-light">
  <div class="n-section-content-one">
    <app-question-home-section></app-question-home-section>
  </div>
</div>

<div class="n-section-contact n-section-lighter">
  <div class="n-section-content-one">

    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <div class="n-list-box-header">
            <h2>Contact Us</h2>
            <p>Happy to hear form you.</p>
          </div>
        </div>
      </div> 
      <app-contact-form></app-contact-form>
    </div>
    
  </div>
</div>

<app-global-footer></app-global-footer>