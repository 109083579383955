import { ViewportScroller } from '@angular/common';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalWindowScrollService {

  private scrollY : BehaviorSubject<number>;
  public scrollY$: Observable<number>;

  constructor(private viewportScroller : ViewportScroller) {

    this.scrollY = new BehaviorSubject(0);
    this.scrollY$ = this.scrollY.asObservable();
  }

  updateScroll(): void {

    var position = this.viewportScroller.getScrollPosition();
    var positionY = position[1];
    this.scrollY.next(positionY);
  }

  getScrollY(): Observable<number> {  

    return this.scrollY$;
  }
}