<div class="container">
  <div class="row">
    <div class="col-md-8">
      <div class="n-list-box-header">
        <h2>Highlights</h2>
        <p>What discoveries are waiting to be made.</p>
      </div>
    </div>
    <div class="col-md-4 d-md-flex align-items-center justify-content-end">
      <a [routerLink]="'/highlights'" [routerLinkActive]="'active'">All Highlights</a>
    </div>
  </div>
  <div class="row n-list-narrow-gutter">
    <div *ngFor="let item of model" class="col-6 col-sm-4 col-md-3">
      <app-highlight-tile [model]="item"></app-highlight-tile>
    </div>
  </div>
</div>