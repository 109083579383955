<app-global-cookie></app-global-cookie>
<app-global-menu></app-global-menu>
<app-global-breadcrumb></app-global-breadcrumb>

<div class="n-section-light n-section-content-one">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <div class="n-list-box-header">
          <h1>{{model!.title}}</h1>
          <p>Our tours.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div *ngFor="let item of model.items" class="col-md-4">
        <app-tour-tile [model]="item"></app-tour-tile>
      </div>
    </div>
  </div>
</div>

<app-global-footer></app-global-footer>