<div class="container">
  <div class="row">
    <div class="col-md-8">
      <div class="n-list-box-header">
        <h2>Tours</h2>
        <p>Our tours</p>
      </div>
    </div>
    <div class="col-md-4 d-md-flex align-items-center justify-content-end">
      <a [routerLink]="'/tours'" [routerLinkActive]="'active'">All Tours</a>
    </div>
  </div>

  <div class="row">
    <div *ngFor="let item of model" class="col-sm-6 col-md-4">

      <app-tour-tile [model]="item"></app-tour-tile>

    </div>
  </div>
</div>