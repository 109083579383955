import { Page } from "./page";
import { Content } from "./content";

export class ContentOverview extends Page 
{
    items : Content[];

    constructor() {
        super();
        this.title = "Contents";
    }
}
