import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalHeaderService } from '../global-header.service';
import { ContextService } from '../context.service';
import { Tour } from '../tour';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tour-home-section',
  templateUrl: './tour-home-section.component.html',
  styleUrls: ['./tour-home-section.component.less']
})
export class TourHomeSectionComponent implements OnInit, OnDestroy {
  
  private subscriptions = new Subscription();

  public model: Tour[];
  
  constructor(
    private contextService: ContextService) {
  }

  ngOnInit() {

    var subscription = this.contextService.getTours().subscribe(data => {
      this.model = data;
    });
    
    this.subscriptions.add(subscription);
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
