<div class="container">
  <div class="row mb-6">
      <div class="col-md-8">
        <div class="n-list-box-header">
          <h2>Questions</h2>
          <p>Common questions we are asked.</p>
        </div>
      </div>
      <div class="col-md-4 d-md-flex align-items-center justify-content-end">
        <a [routerLink]="'/questions'" [routerLinkActive]="'active'">All Questions</a>
      </div>
  </div>
  <div class="row n-list-narrow-gutter">
    <div *ngFor="let item of model" class="col-md-3">

      <app-question-tile [model]="item"></app-question-tile>
      
    </div>
  </div>
</div>