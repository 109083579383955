import { Page } from "./page";
import { Tag } from "./tag";

export class TagOverview extends Page 
{
    items : Tag[];

    constructor() {
        super();
        this.title = "Tags";
    }
}
