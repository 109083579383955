import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class GlobalCookieService {

  constructor(private cookieService: CookieService ) { 
  }

  getAccepted() : boolean { 

    var value = false;
    
    var cookieValue = this.cookieService.get('SstCookiePolicy');

    //this.cookieService.set( 'Test', 'Hello World' );
    
    if(cookieValue === 'true')
    {
      value = true;
    }

    return value; 
  }

  setAccepted() : boolean { 

    var value = true;

    this.cookieService.set('SstCookiePolicy', `${value}`, 365);

    return value; 
  }
}
