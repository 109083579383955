<div class="n-list-box">
    <div class="n-list-box-content">
        <p>{{model!.title}}</p> 
        <p>
            <a *ngIf="!expanded" href="" (click)="toggle($event)">More</a>
            <a *ngIf="expanded" href="" (click)="toggle($event)">Less</a>
        </p>
        <div *ngIf="expanded" class="n-list-box-content-reveal">
            <p *ngFor="let text of model.summary">
                {{text}}
            </p>
        </div> 
    </div>
</div>