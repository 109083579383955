import { Page } from "./page";
import { Image } from "./image";

export class ImageOverview extends Page
{
    items : Image[];

    constructor() {
        super();
        this.title = "Images";
    }
}
