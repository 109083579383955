<div class="container">
  <div class="row">
    <div class="col-md-8">
      <div class="n-list-box-header">
        <h2>About Us</h2>
        <p>A bit more about us.</p>
      </div>
    </div>
    <div class="col-md-4 d-md-flex align-items-center justify-content-end">
      <a [routerLink]="'/content'" [routerLinkActive]="'active'">All Articles</a>
    </div>
  </div>
  <div class="row">
    <div *ngFor="let item of [model[0]]" class="col-md-8">

      <div class="n-list-box-flush">
        <div class="n-list-box-flush-content">
          <h3>About Us</h3>
          <p *ngFor="let text of item.content">{{text.text}}</p>
        </div>
      </div>
      
    </div>
  </div>
</div>