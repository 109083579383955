import { Page } from "./page";
import { Question } from "./question";

export class QuestionOverview extends Page 
{
    items : Question[];

    constructor() {
        super();
        this.title = "Questions";
    }
}
