import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { slideInAnimation } from './global-animation';

// import { trigger, animate, transition, style, query, 
//   animateChild, group } from '@angular/animations';

// export const slideInAnimation =
//   trigger('routeAnimations', [
    
//     //transition('* <=> *', [
//     // transition('* <=> *', [
//     //   style({ position: 'relative' }),
//     //   query(':enter, :leave', [
//     //     style({
//     //       position: 'absolute',
//     //       top: 0,
//     //       left: 0,
//     //       width: '100%'
//     //     })
//     //   ]),

//     //   //query(':enter', [style({ left: '-100%', opacity: 0 })]),
//     //   query(':enter', [style({ left: '100%', opacity: 0 })]),
//     //   query(':leave', animateChild()),
//     //   group([
//     //     //query(':leave', [animate('1s ease-out', style({ left: '100%', opacity: 0 }))]),
//     //     query(':leave', [animate('1s ease-out', style({ left: '0%', opacity: 0 }))]),

//     //     query(':enter', [animate('1s ease-out', style({ left: '0%', opacity: 1 }))])
//     //     ]),
//     //     query(':enter', animateChild())
//     //   ])


// // - `query(":enter")` returned zero elements. (Use `query(":enter", { optional: true })` if you wish to allow this.)
// // - `query(":enter")` returned zero elements. (Use `query(":enter", { optional: true })` if you wish to allow this.)
// // - `query(":enter")` returned zero elements. (Use `query(":enter", { optional: true })` if you wish to allow this.)

//     transition('* <=> *', [
//       style({ position: 'relative' }),
//       query(':enter, :leave', [
//         style({
//           position: 'absolute',
//           top: 0,
//           left: 0,
//           width: '100%'
//         })
//       ]),

//       query(':leave', [style({ opacity: 1 })], { optional: true }),
//       query(':leave', animateChild(), { optional: true }),
//       query(':leave', [animate('0s ease-out', style({ opacity: 0 }))], { optional: true }),

//       query(':enter', [style({ opacity: 0 })], { optional: true }),
//       query(':enter', [animate('1s ease-in', style({ opacity: 1 }))], { optional: true }),
//       query(':enter', animateChild(), { optional: true }),
//     ])
//   ]);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  animations: [
    slideInAnimation
  ],
})
export class AppComponent {
  title = 'Skye Scenic Tours';

  prepareRoute(outlet: RouterOutlet) {
    //return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
    //var url = outlet.activatedRoute.url.pipe(map(segments => segments.join('')));

    var url = window.location.pathname.split('/').join('');

    return url; 
  }
}
