import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalHeaderService } from '../global-header.service';
import { ContextService } from '../context.service';
import { Question } from '../question';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-question-home-section',
  templateUrl: './question-home-section.component.html',
  styleUrls: ['./question-home-section.component.css']
})
export class QuestionHomeSectionComponent implements OnInit, OnDestroy {
  
  private subscriptions = new Subscription();

  public model : Question[]

  constructor(
    private contextService: ContextService) {
  }

  ngOnInit(): void {
    var subscription = this.contextService.getQuestions().subscribe(data => {
      this.model = data;
    });
    
    this.subscriptions.add(subscription);
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
