import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalHeaderService } from '../global-header.service';
import { ContextService } from '../context.service';
import { Why } from '../why';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-why-home-section',
  templateUrl: './why-home-section.component.html',
  styleUrls: ['./why-home-section.component.less']
})
export class WhyHomeSectionComponent implements OnInit, OnDestroy {
  
  private subscriptions = new Subscription();

  public model: Why[];

  constructor(
    private contextService: ContextService) {
  }

  ngOnInit() {
    var subscription = this.contextService.getWhys().subscribe(data => {
      this.model = data;
    });
    
    this.subscriptions.add(subscription);
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}