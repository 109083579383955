<div *ngIf="!isAccepted" class="n-section-cookie">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-md-12">

                <div class="n-list-box-flush">
                    <div class="n-list-box-flush-content">
                        <p>
                            We only use cookies for essential purposes. By continuing to use our site, you agree to our use of cookies. 
                            <button class="btn btn-primary" (click)="accept($event)">Okay</button>
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>