<div class="n-list-box-flush-image" 
[class.n-list-box-flush-image-focus]="hasFocus()" 
(mouseenter)="mouseEnter()" 
(mouseleave)="mouseLeave()"
(click)="click($event)">
  <img class="n-list-box-flush-image-image" [src]="model?.thumbnailUrl" [alt]="model.title" /> 
  <!-- 1600 - 988.854 -->
  <!-- 1600 - 989 -->
  <div class="n-list-box-flush-image-content">
    <h3>{{model!.title}}</h3> 
    <p>
      <a [routerLink]="['/highlights', model.name]">{{model.title}}</a>
    </p>
  </div>
</div>