<div class="container">
  <div class="row">
    <div class="col-md-12">
      
      <div class="n-list-box">
        <div class="n-list-content">
          
          <div class="container">
            <div class="row">
              <div *ngFor="let item of model" class="col-6 col-md-3">

                <app-why-tile [model]="item"></app-why-tile>
                
              </div>
            </div>
          </div>

        </div>
      </div>
      
    </div>
  </div>
</div>