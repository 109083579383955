import { AbstractControl } from '@angular/forms';

export class FormItem {

  constructor(
    private formControl: AbstractControl,
    private formControlName: string) { 
  } 

  get control () : AbstractControl {
    return this.formControl;
  }

  get isValid(): boolean {

    return (this.formControl.valid);
  }

  get isEdited(): boolean {

    return (this.formControl.dirty || this.formControl.touched);
  }

  get isInvalid(): boolean {

    return (this.formControl.invalid) && this.isEdited;
  }

  get name() : string {
    return this.formControlName;
  }

  public validationMessages : Map<string, string>;

  public subscribeValueChanges() {
  }

  public subscribeStatusChanges() {
  }
}