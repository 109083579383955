import { Component, OnInit, Input } from '@angular/core';
import { Question } from '../question';

@Component({
  selector: 'app-question-tile',
  templateUrl: './question-tile.component.html',
  styleUrls: ['./question-tile.component.css']
})
export class QuestionTileComponent implements OnInit {

  @Input()
  public model : Question

  public expanded : boolean 
  
  constructor() { }

  ngOnInit(): void {
    this.expanded = false;
  }

  public toggle(event: MouseEvent) : void
  {
    this.expanded = !this.expanded;

    event.preventDefault();
  }
}