import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalHeaderService } from '../global-header.service';
import { ContextService } from '../context.service';
import { Blog } from '../blog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-blog-home-section',
  templateUrl: './blog-home-section.component.html',
  styleUrls: ['./blog-home-section.component.less']
})
export class BlogHomeSectionComponent implements OnInit, OnDestroy {
  
  private subscriptions = new Subscription();

  public model : Blog[]

  constructor(
    private contextService: ContextService) {
  }

  ngOnInit() {
    var subscription = this.contextService.getBlogs().subscribe(data => {
      this.model = data;
    });
    
    this.subscriptions.add(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
