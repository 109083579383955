<div class="container">
  <div class="carousel slide">
    <ol class="carousel-indicators">
      <li *ngFor="let item of model;let i = index;" (click)="switch(i)" [class.active]="isActive(i)"></li>
    </ol>

    <div class="carousel-inner">
      <div class="carousel-item active">
  
        <div class="row">
          <div class="col-12 col-lg-8 offset-lg-2">

            <div *ngFor="let item of [model[activeIndex]]" class="n-list-box-flush">
              <div class="n-list-box-flush-content">
                <p *ngFor="let text of item.summary">{{text}}</p>
                <p>{{item.who}}, {{item.source}}</p>
              </div>
            </div>

          </div>
        </div>
        
      </div>
    </div>

    <a class="carousel-control-prev" href="#" role="button" (click)="previous($event)">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#" role="button" (click)="next($event)">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>