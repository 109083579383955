import { Component, OnInit, Input } from '@angular/core';
import { Why } from '../why';

@Component({
  selector: 'app-why-tile',
  templateUrl: './why-tile.component.html',
  styleUrls: ['./why-tile.component.css']
})
export class WhyTileComponent implements OnInit {

  @Input()
  public model : Why

  constructor() { }

  ngOnInit(): void {
  }

}