import { Highlight } from "./highlight";
import { Page } from "./page";

export class Tour extends Page
{
    public highlights : Highlight[];

    public points : string[];

    public timeline : string[];

    public pricing : string[];

    public images : string[];

    public conditions : string[];

    public itemId : number;

    constructor() 
    {
        super();
        this.points = [];
        this.timeline = [];
        this.pricing = [];
        this.conditions = [];
    }
}
