import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Highlight } from '../highlight';

@Component({
  selector: 'app-highlight-tile',
  templateUrl: './highlight-tile.component.html',
  styleUrls: ['./highlight-tile.component.css']
})
export class HighlightTileComponent implements OnInit {

  @Input()
  public model : Highlight

  public focus : boolean 
  
  constructor(
    private router: Router,) { }

  ngOnInit(): void {
  }

  public click(event: MouseEvent) : void
  {
    this.router.navigate(['/highlights/' + this.model.name]);

    event.preventDefault();
  }

  public hasFocus(): boolean
  {
    return this.focus == true;
  }

  public mouseEnter(): void
  {
    this.focus = true;
  }

  public mouseLeave():void
  {
    this.focus = false;
  }
}