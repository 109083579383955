import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalHeaderService } from '../global-header.service';
import { ContextService } from '../context.service';
import { BlogOverview } from '../blog-overview';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-blog-overview',
  templateUrl: './blog-overview.component.html',
  styleUrls: ['./blog-overview.component.less']
})
export class BlogOverviewComponent implements OnInit, OnDestroy {
  
  private subscriptions = new Subscription();

  public model : BlogOverview

  constructor(
    private route: ActivatedRoute,
    private contextService: ContextService,
    private headerService: GlobalHeaderService ) {
  }

  ngOnInit() {
    this.model = new BlogOverview();

    var subscription = this.route.paramMap.subscribe(params => {
      var itemsSubscription = this.contextService.getBlogs().subscribe(data => {
        this.model.items = data;
        this.headerService.setHead(this.model);
      });
      
      this.subscriptions.add(itemsSubscription);
    });
    
    this.subscriptions.add(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
