<form [formGroup]="form" (ngSubmit)="save()">
  <div class="row">  
    <div class="col-md-6">
      <div class="n-list-box-flush">
        <div *ngIf="!submitted" class="n-list-box-content-flush">
          <h3>Contact Us form</h3>

          <div class="form-group">
            <label for="name">Name</label>
            <input type="text" id="name"
              class="form-control"
              formControlName="name" 
              placeholder="Enter Name"
              [ngClass]="{'is-invalid':name.isInvalid}" />

            <div *ngIf="name.isInvalid" class="alert alert-danger">
              <div *ngIf="name.control.errors?.required">
                {{name.name}} is required.
              </div>
              <div *ngIf="name.control.errors?.minlength">
                Looks like {{name.name}} is to short, 4 or more characters is good.
              </div>
              <div *ngIf="name.control.errors?.maxlength">
                Looks like {{name.name}} is to long, under 128 characters is good.
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="email">Email</label>
            <input type="text" id="email"
              class="form-control"
              formControlName="email" 
              placeholder="Enter Email"
              [ngClass]="{'is-invalid':email.isInvalid}" />

            <div *ngIf="email.isInvalid" class="alert alert-danger">
              <div *ngIf="email.control.errors?.required">
                {{email.name}} is required.
              </div>
              <div *ngIf="email.control.errors?.minlength">
                Looks like {{email.name}} is to short, over {{email.control.errors.minlength.requiredLength}} characters is good.
              </div>
              <div *ngIf="email.control.errors?.maxlength">
                Looks like {{email.name}} is to long, under {{email.control.errors.maxlength.requiredLength}} characters is good.
              </div>
              <div *ngIf="email.control.errors?.pattern">
                Looks like {{email.name}} is a pattern we dont recognise.
              </div>
            </div>
          </div>  

          <div class="form-group d-none">
            <label for="signature">Signature</label>
            <input type="text" id="signature"
              class="form-control"
              formControlName="signature" 
              placeholder="Enter Signature"
              [ngClass]="{'is-invalid':signature.isInvalid}" />
    
            <div *ngIf="signature.isInvalid" class="alert alert-danger">
              <div *ngIf="signature.control.errors?.required">
                {{signature.name}} is required.
              </div>
              <div *ngIf="signature.control.errors?.minlength">
                {{signature.name}} must be at least {{signature.control.errors.minlength.requiredLength}} characters long.
              </div>
              <div *ngIf="signature.control.errors?.maxlength">
                {{signature.name}} must be at most {{signature.control.errors.maxlength.requiredLength}} characters long.
              </div>
            </div>
          </div>   

          <div class="form-group">
            <label for="message">Message</label>
            <textarea id="message" 
              class="form-control"
              rows="3"
              formControlName="message" 
              placeholder="Enter Message"
              [ngClass]="{'is-invalid':message.isInvalid}">
            </textarea>
    
            <div *ngIf="message.isInvalid" class="alert alert-danger">
              <div *ngIf="message.control.errors?.required">
                {{message.name}} is required.
              </div>
              <div *ngIf="message.control.errors?.minlength">
                {{message.name}} must be at least {{message.control.errors.minlength.requiredLength}} characters long.
              </div>
              <div *ngIf="message.control.errors?.maxlength">
                {{message.name}} must be at most {{signature.control.errors.minlength.requiredLength}} characters long.
              </div>
            </div>
          </div>

          <div class="form-group">
            <button type="submit" class="btn btn-primary">Send</button>
          </div>

        </div>

        <div *ngIf="submitted"class="n-list-box-content-flush">
          <h3>Thanks</h3>
          <p>
            Thanks for your submission {{name.value}}
          </p> 
        </div>
      </div>
    </div>
    <div class="col-md-6 d-none d-md-block"> 
      <div class="n-list-box-flush">
        <div class="n-list-box-content-flush">
          <h3>Address</h3>
          <p>
            Skye Scenic Tours<br />
            Portree<br />
            Flodigarry<br />
            Isle of Skye<br />
            IV51 9HZ<br />
          </p>
          <h3>Phone</h3>
          <p>
              Call 01470 552771
          </p>
        </div>
      </div>

    </div>  
  </div>
</form>