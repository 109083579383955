import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalHeaderService } from '../global-header.service';
import { ContextService } from '../context.service';
import { TourOverview } from '../tour-overview';
import { Tour } from '../tour';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tour-overview',
  templateUrl: './tour-overview.component.html',
  styleUrls: ['./tour-overview.component.less']
})
export class TourOverviewComponent implements OnInit, OnDestroy {
  
  private subscriptions = new Subscription();

  public model: TourOverview;

  constructor(
    private route: ActivatedRoute,
    private contextService: ContextService,
    private headerService: GlobalHeaderService ) {
  }

  ngOnInit() {

    this.model = new TourOverview();

    var subscription = this.route.paramMap.subscribe(params => {
      var itemsSubscription = this.contextService.getTours().subscribe(data => {
        this.model.items = data;
        this.headerService.setHead(this.model);
      });
      
      this.subscriptions.add(itemsSubscription);
    });
    
    this.subscriptions.add(subscription);
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}