import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalWindowScrollService } from '../global-window-scroll.service';

@Component({
  selector: 'app-global-menu',
  templateUrl: './global-menu.component.html',
  styleUrls: ['./global-menu.component.css']
})
export class GlobalMenuComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();

  public isHome : boolean;

  public isScrolled : boolean;

  public isExpanded : boolean;

  constructor(
    private route: ActivatedRoute,
    private windowScrollService: GlobalWindowScrollService) {
  }

	@HostListener('window:scroll') onScroll(e: Event): void {
    this.windowScrollService.updateScroll();
  }

  ngOnInit() {
    this.calcIsHome(window.location.pathname);

    var subscription = this.windowScrollService.getScrollY()
      .subscribe(scrollY => {
        this.calcIsScrolled(scrollY); 
      });

    this.subscriptions.add(subscription);

    this.isExpanded = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public toggle(event: MouseEvent) : void
  {
    this.isExpanded = !this.isExpanded;

    event.preventDefault();
  }

  private calcIsHome(pathname : string): void
  {
    this.isHome = false;

    var url = pathname.split('/').join('');

    if (url.length == 0)
    {
      this.isHome = true;
    }
    else
    {
      this.isHome = false;
    }
  }

  private calcIsScrolled(scrollY: number) : void
  {
    if(scrollY > 20)
    {
      this.isScrolled = true;
    }
    else 
    {
      this.isScrolled = false;
    }
  }
}