import { Injectable } from '@angular/core';
import { AppConfig } from './app-config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AppConfigService {

  constructor(private http: HttpClient) {}
  
  get(): Observable<AppConfig> {

    return this.http.get<AppConfig>('/assets/app.config.json');
  }
}