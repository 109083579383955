import { Component, OnInit } from '@angular/core';
import { GlobalCookieService } from '../global-cookie.service';

@Component({
  selector: 'app-global-cookie',
  templateUrl: './global-cookie.component.html',
  styleUrls: ['./global-cookie.component.css']
})
export class GlobalCookieComponent implements OnInit {

  public isAccepted : boolean;

  public isExpanded : boolean;

  constructor(private cookieService : GlobalCookieService) { 
  }
  
  ngOnInit(): void {
    this.isAccepted = this.cookieService.getAccepted();
    this.isExpanded = false;
  }

  public accept(event: MouseEvent) : void
  {
    this.isAccepted = this.cookieService.setAccepted();
    
    event.preventDefault();
  }

  public toggle(event: MouseEvent) : void
  {
    this.isExpanded = !this.isExpanded;

    event.preventDefault();
  }
}
