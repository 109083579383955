import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-global-breadcrumb',
  templateUrl: './global-breadcrumb.component.html',
  styleUrls: ['./global-breadcrumb.component.css']
})
export class GlobalBreadcrumbComponent implements OnInit {

  HomeLinkText : string = "Home";
  HomeUrl : string = "/";
  Dash : string = "-";
  Empty : string = "";
  Space : string = " ";

  model : Breadcrumb[];

  constructor(
    private route: ActivatedRoute
    ) {

    this.model = [];
  }

  ngOnInit() {

    this.createBreadcrumb();
  }

  private createBreadcrumb() : void
  {
    var home = new Breadcrumb();
    home.url = this.HomeUrl;
    home.linkText = this.HomeLinkText;

    this.model.push(home);

    this.model = this.createBreadcrumbFirstChild(this.route.root, home.url, this.model);
  }

  private createBreadcrumbFirstChild(
    route : ActivatedRoute, 
    url: string,
    breadcrumbs : Breadcrumb[]) : Breadcrumb[]
  {    
    if (route.snapshot.url.length > 0)
    {
      var name = route.snapshot.url.map(segment => segment.path).join(); 

      var breadcrumb = new Breadcrumb();
      breadcrumb.url = `${url}/${name}/`;
      breadcrumb.linkText = this.titleCase(name);

      breadcrumbs.push(breadcrumb);
    }

    if (route.firstChild != null)
    {
      var lastIndex = breadcrumbs.length - 1;
      breadcrumbs = this.createBreadcrumbFirstChild(route.firstChild, breadcrumbs[lastIndex].url, breadcrumbs);
    }

    return breadcrumbs;
  }

  private titleCase(source: string) : string {
    var transformation = source.toLowerCase().split(this.Dash);

    for (var i = 0; i < transformation.length; i++) {
      transformation[i] = transformation[i].charAt(0).toUpperCase() + transformation[i].slice(1); 
    }

    return transformation.join(this.Space);
  }
}

export class Breadcrumb 
{
    public url : string

    public linkText : string
}
