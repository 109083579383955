import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AccommodationOverviewComponent } from './accommodation-overview/accommodation-overview.component';
import { AccommodationComponent } from './accommodation/accommodation.component';
import { HighlightComponent } from './highlight/highlight.component';
import { HighlightOverviewComponent } from './highlight-overview/highlight-overview.component';
import { ImageOverviewComponent } from './image-overview/image-overview.component';
import { ImageComponent } from './image/image.component';
import { TourOverviewComponent } from './tour-overview/tour-overview.component';
import { TourComponent } from './tour/tour.component';
import { WhyOverviewComponent } from './why-overview/why-overview.component';
import { WhyComponent } from './why/why.component';
import { BlogOverviewComponent } from './blog-overview/blog-overview.component';
import { BlogComponent } from './blog/blog.component';
import { BookOverviewComponent } from './book-overview/book-overview.component';
import { BookComponent } from './book/book.component';
import { ContentOverviewComponent } from './content-overview/content-overview.component';
import { ContentComponent } from './content/content.component';
import { FormOverviewComponent } from './form-overview/form-overview.component';
import { FormComponent } from './form/form.component';
import { QuestionOverviewComponent } from './question-overview/question-overview.component';
import { QuestionComponent } from './question/question.component';
import { ReviewOverviewComponent } from './review-overview/review-overview.component';
import { ReviewComponent } from './review/review.component';
import { TagOverviewComponent } from './tag-overview/tag-overview.component';
import { TagComponent } from './tag/tag.component';
import { GlobalNotFoundComponent } from './global-not-found/global-not-found.component';

// const routes: Routes = [
//   //{ path: '', component: HomeComponent, data: { animation: 'HomePage'} },
//   { path: '', component: HomeComponent },
//   { path: 'accommodation', component: AccommodationOverviewComponent },
//   { path: 'accommodation/:name', component: AccommodationComponent },
//   { path: 'blogs', component: BlogOverviewComponent },
//   { path: 'blogs/:name', component: BlogComponent },
//   { path: 'book', component: BookOverviewComponent },
//   { path: 'book/:name', component: BookComponent },
//   { path: 'content', component: ContentOverviewComponent },
//   //{ path: 'content/:name', component: ContentComponent, data: {animation: 'AboutPage'}  },
//   { path: 'content/:name', component: ContentComponent  },
//   { path: 'forms', component: FormOverviewComponent },
//   { path: 'forms/:name', component: FormComponent },
//   { path: 'highlights', component: HighlightOverviewComponent },
//   { path: 'highlights/:name', component: HighlightComponent },
//   { path: 'images', component: ImageOverviewComponent },
//   { path: 'images/:name', component: ImageComponent },
//   { path: 'questions', component: QuestionOverviewComponent },
//   { path: 'questions/:name', component: QuestionComponent },
//   { path: 'reviews', component: ReviewOverviewComponent },
//   { path: 'reviews/:name', component: ReviewComponent },
//   { path: 'tags', component: TagOverviewComponent },
//   { path: 'tags/:name', component: TagComponent },
//   //{ path: 'tours', component: TourOverviewComponent, data: {animation: 'FilterPage'} },
//   { path: 'tours', component: TourOverviewComponent },
//   { path: 'tours/:name', component: TourComponent },
//   { path: 'why', component: WhyOverviewComponent },
//   { path: 'why/:name', component: WhyComponent },
// ];

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: HomeComponent,
        data: {
          breadcrumb: 'Home'
        },
      },
      { 
        path: 'accommodation', 
        children: [ 
          { 
            path: '', 
            component: AccommodationOverviewComponent,
            data: {
              breadcrumb: 'Accommodation'
            },
          }, 
          { 
            path: ':name', 
            component: AccommodationComponent,
            data: {
              breadcrumb: ':name'
            },
          }
        ] 
      },
      { 
        path: 'blogs', 
        children: [ 
          { 
            path: '', 
            component: BlogOverviewComponent 
          }, 
          { 
            path: ':name', 
            component: BlogComponent 
          }
        ] 
      },
      { 
        path: 'book', 
        children: [ 
          { 
            path: '', 
            component: BookOverviewComponent 
          }, 
          { 
            path: ':name', 
            component: BookComponent 
          }
        ] 
      },
      { 
        path: 'content', 
        children: [ 
          { 
            path: '', 
            component: ContentOverviewComponent 
          }, 
          { 
            path: ':name', 
            component: ContentComponent 
          }
        ] 
      },
      { 
        path: 'forms', 
        children: [ 
          { 
            path: '', 
            component: FormOverviewComponent 
          }, 
          { 
            path: ':name', 
            component: FormComponent 
          }
        ] 
      },
      { 
        path: 'highlights', 
        children: [ 
          { 
            path: '', 
            component: HighlightOverviewComponent 
          }, 
          { 
            path: ':name', 
            component: HighlightComponent 
          }
        ] 
      },
      { 
        path: 'images', 
        children: [ 
          { 
            path: '', 
            component: ImageOverviewComponent 
          }, 
          { 
            path: ':name', 
            component: ImageComponent 
          }
        ] 
      },
      { 
        path: 'questions', 
        children: [ 
          { 
            path: '', 
            component: QuestionOverviewComponent 
          }, 
          { 
            path: ':name', 
            component: QuestionComponent 
          }
        ] 
      },
      { 
        path: 'reviews', 
        children: [ 
          { 
            path: '', 
            component: ReviewOverviewComponent 
          }, 
          { 
            path: ':name', 
            component: ReviewComponent 
          }
        ] 
      },
      { 
        path: 'tags', 
        children: [ 
          { 
            path: '', 
            component: TagOverviewComponent 
          }, 
          { 
            path: ':name', 
            component: TagComponent 
          }
        ] 
      },
      { 
        path: 'tours', 
        children: [ 
          { 
            path: '', 
            component: TourOverviewComponent 
          }, 
          { 
            path: ':name', 
            component: TourComponent 
          }
        ] 
      },
      { 
        path: 'why', 
        children: [ 
          { 
            path: '', 
            component: WhyOverviewComponent 
          }, 
          { 
            path: ':name', 
            component: WhyComponent 
          }
        ] 
      },
      //Redirects
      { 
        path: 'terms-and-conditions.html',
        redirectTo: '/content/terms-and-conditions', 
        pathMatch: 'full' 
      }, 
      { 
        path: 'tours.html',
        redirectTo: '/tours', 
        pathMatch: 'full' 
      },  
      { 
        path: 'about.html',
        redirectTo: '/content/about', 
        pathMatch: 'full' 
      },   
      { 
        path: 'contact.html',
        redirectTo: '/content/contact', 
        pathMatch: 'full' 
      }, 
      //404
      {
        path: '**',
        component: GlobalNotFoundComponent
      }
    ]
  }
];




//routerState: RouterState

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
}
    )],
  exports: [RouterModule]
})
export class AppRoutingModule { }