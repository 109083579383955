import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Content } from '../content';
import { ContextService } from '../context.service';
import { GlobalHeaderService } from '../global-header.service';

@Component({
  selector: 'app-global-not-found',
  templateUrl: './global-not-found.component.html',
  styleUrls: ['./global-not-found.component.css']
})
export class GlobalNotFoundComponent implements OnInit {

  public model : Content;

  constructor(
    private route: ActivatedRoute,
    private contextService: ContextService,
    private headerService: GlobalHeaderService ) {
  }

  ngOnInit() {
    
    var pageNotFound : Content = new Content();
    pageNotFound.name = "page-not-found";
    pageNotFound.title = "Page Not Found";
    pageNotFound.linkText = "Page Not Found";
    pageNotFound.description = "Page cannot be found.";
    pageNotFound.summary = [
      "Page has moved or you've mistyped."
    ];
    pageNotFound.content = [
      "Oops looks like you've mistyped or the page you are looking for has moved."
    ];

    this.model = pageNotFound;
    this.headerService.setHead(this.model);
    this.headerService.setNoIndex();
  }
}
