import { Page } from "./page";
import { Tour } from "./tour";

export class TourOverview extends Page
{
    items : Tour[];

    constructor() {
        super();
        this.title = "Tours";
        this.description = "Isle of Skye Tours, group and private Tours of the Isle of Skye. Discover the many breathtaking views such as The Old Man of Storr, Kilt Rock, the Quiraing, Neist Point, the Fairy Pools and undiscovered Isle of Skye.";
        this.keywords = "Isle of Skye Day Tours, Group Tours, Private Tours, Departs Portree, Departs North Skye, Old Man of Storr, Quiraing, Neist Point, Fairy Pools, Undiscovered Skye";
    }
}