<app-global-cookie></app-global-cookie>
<app-global-menu></app-global-menu>
<app-global-breadcrumb></app-global-breadcrumb>

<div class="n-section-light n-section-content-one">
  <div class="container">
    <div class="row">
      <div class="col-12">

        <div class="n-list-box">
          <div class="n-list-box-content">
            <p *ngFor="let text of model.summary">
                {{text}}
            </p>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<app-global-footer></app-global-footer>